import type {
  ClaimAttachmentApiEndpoint,
  ClaimAttachmentType,
  ClaimSignedUrlEndpoint,
} from '@cigna/enac-provider-web/shared/util';
import FileUploadItem from '../file-upload-item/file-upload-item';

import styles from './file-upload-list.module.scss';

export interface FileUploadListProps {
  uploadedFiles: UploadFile[];
  attachmentType: ClaimAttachmentType;
  metadataApiEndpoint: ClaimAttachmentApiEndpoint;
  signedUrlApiEndpoint: ClaimSignedUrlEndpoint;
  serviceReferenceNumber: string;
  onRemoveFile?: (file: File) => void;
}

export interface UploadFile {
  file: File;
  isValid: boolean;
  errorMsg: string;
}

const FileUploadList = (props: FileUploadListProps) => (
  <>
    {props.uploadedFiles.map((uf, i) => (
      <FileUploadItem
        data-test-id={`file-upload-item-${i}`}
        file={uf.file}
        isValid={uf.isValid}
        attachmentType={props.attachmentType}
        metadataApiEndpoint={props.metadataApiEndpoint}
        signedUrlApiEndpoint={props.signedUrlApiEndpoint}
        serviceReferenceNumber={props.serviceReferenceNumber}
        onRemoveFile={props.onRemoveFile}
        key={uf.file.name}
      />
    ))}
  </>
);

export default FileUploadList;

import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LeafSkeletonLoader } from '@esi/leaf-web/lib/react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useGetUserProfileApi,
  type UserEntitlement,
} from '@cigna/enac-provider-web/shared/data-access';

const LoadingSkeleton = () => (
  <div className="enac-g-page-wrap">
    <div style={{ paddingTop: '4.5em' }}>
      <LeafSkeletonLoader height="54px" width="275px" variant="rectangle" />
    </div>
  </div>
);

export const RouteAuthGuard = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { returnTo: pathname } });
    }
  }, [isLoading, pathname, isAuthenticated, loginWithRedirect]);

  return isLoading || !isAuthenticated ? <LoadingSkeleton /> : children;
};

interface EntitlementGuardProps {
  children: React.ReactNode;
  hasEntitlement: UserEntitlement;
}

export const RouteEntitlementGuard = (props: EntitlementGuardProps) => {
  const { data, isSuccess, isError, isLoading } = useGetUserProfileApi();

  if (isSuccess) {
    if (data.identity.entitlements.includes(props.hasEntitlement)) {
      return props.children;
    }

    return <Navigate to={'/dashboard'} />;
  }
  if (isError) {
    return <Navigate to={'/dashboard'} />;
  }

  return <LoadingSkeleton />;
};

import { useState, type PropsWithChildren } from 'react';

import caretDownIcon from '@esi/leaf-icons/lib/icons/System/Regular/Caret Down.svg?raw';
import caretUpIcon from '@esi/leaf-icons/lib/icons/System/Regular/Caret Up.svg?raw';
import LeafIcon from '@esi/leaf-icons/lib/react/LeafIcon';

import styles from './show-more-link.module.scss';

export interface ShowMoreLinkProps {
  showLabel: string;
  collapseLabel: string;
}

export function ShowMoreLink(props: PropsWithChildren<ShowMoreLinkProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const randomId = Math.floor(Math.random() * 900000) + 100000;
  const ariaControlsId = `show-more-details-${randomId}`;

  return (
    <div className={styles.container}>
      {isOpen && (
        <div data-test-id="show-more-child-container" id={ariaControlsId}>
          {props.children}
        </div>
      )}

      <button
        aria-controls={ariaControlsId}
        aria-expanded={isOpen}
        data-test-id="show-more-button"
        className={styles.showMoreButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? props.collapseLabel : props.showLabel}
        <LeafIcon
          svg={isOpen ? caretUpIcon : caretDownIcon}
          key={`showmoreicon-${isOpen}`}
          aria-hidden
        />
      </button>
    </div>
  );
}

export default ShowMoreLink;

import { format, isDate, parse, parseISO } from 'date-fns';

export const maskEmail = (email: string): string => {
  const parts = email.split('@');

  return `${parts[0].charAt(0)}∙∙∙∙∙∙∙∙${parts[0].charAt(
    parts[0].length - 1,
  )}@${parts[1]}`;
};

export const maskPhone = (phone: string): string =>
  `∙∙∙ - ∙∙∙ - ${phone.slice(-4)}`;

export const formatPhone = (phone: string): string => {
  // normalize string and remove all unnecessary characters
  const phoneNumber = phone.replace(/[^\d]/g, '');

  // check if number length equals to 10
  if (phoneNumber.length === 10) {
    // reformat and return phone number
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
  } else if (phoneNumber.length === 11) {
    return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1.$2.$3.$4');
  }

  return phoneNumber;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const getFileSize = (bytes: number, si: boolean = false, dp = 2) => {
  let inputBytes = bytes;
  const thresh = si ? 1000 : 1024;

  if (Math.abs(inputBytes) < thresh) {
    return `${inputBytes} B`;
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    inputBytes /= thresh;
    u = u + 1;
  } while (
    Math.round(Math.abs(inputBytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${inputBytes.toFixed(dp)} ${units[u]}`;
};

export const peakApiDateCompactFormat = 'yyyyMMdd';
export const peakApiDateTimeFormat = 'yyyy-MM-dd HH:mm:ss'; // "2024-03-21 00:00:00"
export const apiStandardDateFormat = 'yyyy-MM-dd';
export const uiDateFormat = 'MM/dd/yyyy';

export const formatDateForUI = (
  date?: string,
  config?: {
    pattern?: string;
    isoDate?: boolean;
  },
): string => {
  if (!date) {
    return '';
  }

  const isValidDate = config?.isoDate
    ? isDate(parseISO(date))
    : isDate(parse(date, config?.pattern || apiStandardDateFormat, new Date()));

  if (isValidDate) {
    try {
      return config?.isoDate
        ? format(parseISO(date), uiDateFormat)
        : format(
            parse(date, config?.pattern || apiStandardDateFormat, new Date()),
            uiDateFormat,
          );
    } catch {
      return date;
    }
  }

  return date;
};

export const formatDateForAPI = (date: string): string =>
  format(
    parse(date, peakApiDateCompactFormat, new Date()),
    apiStandardDateFormat,
  );

export const formatCurrencyForUi = (
  amount: string | number,
  fallback?: string,
): string =>
  amount
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol',
      }).format(
        typeof amount === 'number' ? amount : parseFloat(amount as string),
      )
    : fallback || '--';

export const getAgeFromDOB = (dob: string): number => {
  const timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
  return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
};

export const formatNumber = (num: number) =>
  new Intl.NumberFormat('en-US').format(num);

import { useState } from 'react';
import styles from './array-show-more-link.module.scss';

export interface ArrayShowMoreLinkProps {
  data: string[];
}

export function ArrayShowMoreLink(props: ArrayShowMoreLinkProps) {
  const [isOpen, setIsOpen] = useState(false);

  const ariaControlsId = `show-more-details-${new Date().getTime()}`;
  return (
    <div className={styles.container} id={ariaControlsId}>
      {!isOpen && (
        <span>
          {props.data[0]}
          {props.data.length > 1 && (
            <>
              ,{' '}
              <button
                className="enac-g-button-link"
                aria-controls={ariaControlsId}
                aria-expanded={isOpen}
                data-test-id="show-more-button"
                onClick={() => setIsOpen(!isOpen)}
              >
                {` +${props.data.length - 1} more`}
              </button>
            </>
          )}
        </span>
      )}
      {isOpen && (
        <ul className={styles.list}>
          {props.data.map((uf, i) => (
            <li key={`data-list-${i}`}>
              {uf}
              {i !== props.data.length - 1 && ','}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ArrayShowMoreLink;

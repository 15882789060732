import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  LeafButton,
  LeafField,
  LeafTag,
  LeafTagList,
} from '@esi/leaf-web/lib/react';
import {
  type Duration,
  format,
  parse,
  sub,
  isSameDay,
  isToday,
  subYears,
} from 'date-fns';
import { apiStandardDateFormat } from '@cigna/enac-provider-web/shared/util';
import FilterToggle from '../filter-toggle/filter-toggle';
import styles from './filter-panel-date.module.scss';

export interface FilterPanelDateProps {
  btnLabel: string;
  rangeOptions: DaterangeOption[];
  startDate: string;
  endDate: string;
  onApply: (value: FormValues) => void;
  onCancel?: () => void;
  testId: string;
  disabled?: boolean;
}

interface FormValues {
  startDate: string;
  endDate: string;
}

export interface DaterangeOption {
  label: string;
  duration: Duration;
}

export function FilterPanelDate(props: FilterPanelDateProps) {
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const {
    register,
    watch,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      startDate: props.startDate || format(new Date(), apiStandardDateFormat),
      endDate: props.endDate || format(new Date(), apiStandardDateFormat),
    },
  });

  const watchEndDate = watch('endDate');
  const watchStartDate = watch('startDate');

  const minStartDate = format(subYears(new Date(), 2), apiStandardDateFormat);
  const maxStartDate = watchEndDate
    ? format(
        parse(watchEndDate, apiStandardDateFormat, new Date()),
        apiStandardDateFormat,
      )
    : format(new Date(), apiStandardDateFormat);

  const minEndDate = watchStartDate
    ? format(
        parse(watchStartDate, apiStandardDateFormat, new Date()),
        apiStandardDateFormat,
      )
    : format(subYears(new Date(), 2), apiStandardDateFormat);
  const maxEndDate = format(new Date(), apiStandardDateFormat);

  const handleRangeChoice = (duration: Duration) => {
    setValue(
      'startDate',
      format(sub(new Date(), duration), apiStandardDateFormat),
    );
    setValue('endDate', format(new Date(), apiStandardDateFormat));

    trigger();
  };

  const handleApply = () => {
    if (isValid) {
      props.onApply({
        startDate: getValues('startDate'),
        endDate: getValues('endDate'),
      });
      setShowFilter(false);
    }
  };

  const handleCancel = () => {
    reset();
    setShowFilter(false);
  };

  const handleOpen = () => {
    setValue(
      'startDate',
      props.startDate || format(new Date(), apiStandardDateFormat),
    );
    setValue(
      'endDate',
      props.endDate || format(new Date(), apiStandardDateFormat),
    );
    setShowFilter(true);
  };

  const handleClose = () => {
    setShowFilter(false);
  };

  const getDurationLabel = (startDate: Date, endDate: Date): string => {
    const endsToday = isToday(new Date(endDate));

    if (endsToday) {
      if (isSameDay(startDate, sub(new Date(), { days: 30 }))) {
        return 'Last 30 days';
      } else if (isSameDay(startDate, sub(new Date(), { days: 60 }))) {
        return 'Last 60 days';
      } else if (isSameDay(startDate, sub(new Date(), { days: 90 }))) {
        return 'Last 90 days';
      } else if (isSameDay(startDate, sub(new Date(), { years: 2 }))) {
        return 'Last 2 years';
      }
    }

    return 'Custom range';
  };

  const currentDurationLabel = getDurationLabel(
    parse(getValues('startDate'), apiStandardDateFormat, new Date()),
    parse(getValues('endDate'), apiStandardDateFormat, new Date()),
  );

  const selectedDurationLabel = getDurationLabel(
    parse(props.startDate, apiStandardDateFormat, new Date()),
    parse(props.endDate, apiStandardDateFormat, new Date()),
  );

  return (
    <FilterToggle
      label={`${props.btnLabel}: ${selectedDurationLabel}`}
      isOpen={showFilter}
      onOpen={handleOpen}
      onClosed={handleClose}
      panelWidth="375px"
      testId={props.testId}
      disabled={props.disabled}
    >
      <div data-test-id={'filter-toggle-content'}>
        <div>
          <LeafTagList>
            {props.rangeOptions.map((option) => (
              <LeafTag
                type="radio"
                key={option.label}
                text={option.label}
                shape="round"
                isSelected={currentDurationLabel === option.label}
                variant={
                  currentDurationLabel === option.label
                    ? 'branded'
                    : 'neutral-hybrid'
                }
                onClick={() => handleRangeChoice(option.duration)}
              />
            ))}
          </LeafTagList>
          <div className={styles.dateInputs}>
            <LeafField
              {...register(`startDate`, {
                validate: (value) => {
                  const date = parse(value, apiStandardDateFormat, new Date());
                  return (
                    (date >=
                      parse(minStartDate, apiStandardDateFormat, new Date()) &&
                      date <=
                        parse(
                          maxStartDate,
                          apiStandardDateFormat,
                          new Date(),
                        )) ||
                    'Start date must be within last two years and not after end date'
                  );
                },
              })}
              type="date"
              label="Start date"
              data-test-id="input-start-date"
              style={{ width: '168px' }}
              min={minStartDate}
              max={maxStartDate}
              isError={!!errors.startDate}
              errorNote={errors.startDate?.message}
            />
            <LeafField
              {...register(`endDate`, {
                validate: (value) => {
                  const date = parse(value, apiStandardDateFormat, new Date());
                  return (
                    (date >=
                      parse(minEndDate, apiStandardDateFormat, new Date()) &&
                      date <=
                        parse(maxEndDate, apiStandardDateFormat, new Date())) ||
                    'End date must be within last two years and not before start date'
                  );
                },
              })}
              type="date"
              label="End date"
              data-test-id="input-end-date"
              style={{ width: '168px' }}
              min={minEndDate}
              max={maxEndDate}
              isError={!!errors.endDate}
              errorNote={errors.endDate?.message}
            />
          </div>
        </div>
        <div className={styles.panelFooter}>
          <LeafButton
            data-test-id={`${props.testId}-apply-btn`}
            onClick={handleApply}
            text="Apply"
            variant="primary"
            size="sm"
            fullWidth
          />
          <LeafButton
            data-test-id={`${props.testId}-cancel-btn`}
            onClick={handleCancel}
            text="Cancel"
            variant="tertiary"
            size="sm"
            fullWidth
          />
        </div>
      </div>
    </FilterToggle>
  );
}

export default FilterPanelDate;

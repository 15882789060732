import { LeafPagination } from '@esi/leaf-web/lib/react';
import { formatNumber } from '@cigna/enac-provider-web/shared/util';
import styles from './pagination.module.scss';

export interface PaginationState {
  /** pageIndex value should be start with 0 index */
  pageIndex: number;
  pageSize: number;
}

export interface PaginationProps extends PaginationState {
  /** Total records count value */
  rowCount: number;
  display?: 'compact' | 'icon-only' | 'default';
  visiblePages?: number;
  hideLastAndFirstButtons?: boolean;
  hideRecordCountText?: boolean;
  onPageChange?: (paginationState: PaginationState) => void;
}

export function Pagination(props: PaginationProps) {
  const handlePageChange = (newPageIndex: number) => {
    props.onPageChange &&
      props.onPageChange({ pageIndex: newPageIndex, pageSize: props.pageSize });
  };

  return (
    <>
      {props.rowCount > 0 && (
        <div className={styles.paginationContainer}>
          <div
            className={styles.pageCounts}
            data-test-id="pagination-page-counts"
          >
            {!props.hideRecordCountText && (
              <span>
                {props.pageIndex * props.pageSize + 1}-
                {props.rowCount <=
                props.pageIndex * props.pageSize + props.pageSize
                  ? props.rowCount
                  : props.pageIndex * props.pageSize + props.pageSize}{' '}
                of {formatNumber(props.rowCount)}
              </span>
            )}
          </div>
          <div className="enac-g-hide-for-print">
            <LeafPagination
              data-test-id="leaf-pagination-ctrl"
              pageSize={props.pageSize}
              display={props.display || 'default'}
              visiblePages={props.visiblePages || 5}
              hideLastAndFirstButtons={props.hideLastAndFirstButtons}
              currentPage={props.pageIndex + 1}
              totalResults={props.rowCount}
              onClick={(e) => handlePageChange(e.currentTarget.currentPage - 1)}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Pagination;

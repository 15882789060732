import LeafIcon from '@esi/leaf-icons/lib/react/LeafIcon';
import styles from './circle-icon-jumbo.module.scss';

export interface CircleIconJumboProps {
  svgIcon: string;
}

export function CircleIconJumbo(props: CircleIconJumboProps) {
  return (
    <div className={styles.container}>
      <LeafIcon svg={props.svgIcon} height={64} width={64} aria-hidden />
    </div>
  );
}

export default CircleIconJumbo;

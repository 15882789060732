import { useCallback } from 'react';
import { getEnglishAnalyticsValue } from '@cigna/shared/analytics/core';
import { useAnalytics } from '../context/AnalyticsProvider';
import type { WebActionData, WebPageActionEvent } from '../interfaces';
import type { EventConfigData } from '../models/config.model';
import type { AdditionalDataType } from '../models/data.model';
import { useAutoEventConfig } from './useAutoEventConfig';

const useTrackPageAction = () => {
  const analyticsService = useAnalytics();
  const getEventConfig = useAutoEventConfig();

  return useCallback(
    (
      actionData: WebActionData,
      eventConfig?: EventConfigData,
      additionalData?: AdditionalDataType,
    ) => {
      if (actionData.controlText) {
        actionData.controlText = getEnglishAnalyticsValue(
          actionData.controlText,
        );
      }
      const autoEventConfig = getEventConfig('PageAction', eventConfig);
      const config = eventConfig ? eventConfig : {};
      const event: WebPageActionEvent = {
        actionData,
        event: 'pageAction',
        ...(eventConfig?.includeAutoTrackingData ? autoEventConfig : config),
      };

      if (additionalData?.formData) {
        event.formData = additionalData.formData;
      }

      if (additionalData?.vendorName) {
        event.vendorName = additionalData.vendorName;
      }

      if (additionalData?.userInfo) {
        event.userInfo = { ...event.userInfo, ...additionalData.userInfo };
      }

      analyticsService?.trackPageAction(event);
    },
    [analyticsService, getEventConfig],
  );
};

export { useTrackPageAction };

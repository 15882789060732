import currencyIcon from '@esi/leaf-icons/lib/icons/System/Regular/Currency.svg?raw';
import errorIcon from '@esi/leaf-icons/lib/icons/System/Regular/Error.svg?raw';
import infoIcon from '@esi/leaf-icons/lib/icons/System/Regular/Info.svg?raw';
import refreshIcon from '@esi/leaf-icons/lib/icons/System/Regular/Refresh.svg?raw';
import successIcon from '@esi/leaf-icons/lib/icons/System/Regular/Success.svg?raw';
import warningIcon from '@esi/leaf-icons/lib/icons/System/Regular/Warning.svg?raw';

import { LeafBadge } from '@esi/leaf-web/lib/react';
import type { LeafBadge as LeafBadgeType } from '@esi/leaf-web/lib/wc/components/badge/badge';
import { ClaimStatus } from '@cigna/enac-provider-web/shared/util';

export interface ClaimStatusBadgeProps {
  status: string;
}

export function ClaimStatusBadge(props: ClaimStatusBadgeProps) {
  const claimStatus = props.status.toLowerCase();

  let badgeStatus: LeafBadgeType['status'] = 'neutral';
  let badgeVariant: LeafBadgeType['variant'] = 'subtle';

  let badgeIcon = '';

  if (claimStatus === ClaimStatus.Processed.toLowerCase()) {
    badgeStatus = 'notification';
    badgeIcon = successIcon;
  } else if (claimStatus === ClaimStatus.Paid.toLowerCase()) {
    badgeStatus = 'success';
    badgeIcon = currencyIcon;
  } else if (claimStatus === ClaimStatus.InProgress.toLowerCase()) {
    badgeStatus = 'neutral';
    badgeIcon = refreshIcon;
  } else if (claimStatus === ClaimStatus.Pended.toLowerCase()) {
    badgeStatus = 'warning';
    badgeIcon = warningIcon;
  } else if (claimStatus === ClaimStatus.Denied.toLowerCase()) {
    badgeStatus = 'error';
    badgeIcon = errorIcon;
  } else if (claimStatus === 'processed through cigna') {
    badgeStatus = 'neutral';
    badgeVariant = 'strong';
    badgeIcon = infoIcon;
  }

  return (
    <LeafBadge
      key={`badge-${props.status}`}
      text={props.status}
      status={badgeStatus}
      variant={badgeVariant}
      svg={badgeIcon ? badgeIcon : undefined}
    ></LeafBadge>
  );
}

export default ClaimStatusBadge;

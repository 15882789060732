import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import caretDown from '@esi/leaf-icons/lib/icons/System/Regular/Caret Down.svg?raw';
import externalLinkIcon from '@esi/leaf-icons/lib/icons/System/Regular/External Link.svg?raw';
import LeafIcon from '@esi/leaf-icons/lib/react/LeafIcon';
import { useTrackPageAction } from '@cigna/shared/react/analytics-util';
import type { MainNavLink } from '../../api/use-navigation-api';
import styles from './main-navigation-item.module.scss';

export interface MainNavItemProps {
  link: MainNavLink;
}

export function MainNavigationItem(props: MainNavItemProps) {
  const { t } = useTranslation();
  const trackPageAction = useTrackPageAction();

  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);

  const handleMouseEnter = () => {
    if (props.link.children && props.link.children.length > 0) {
      setMenuVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (props.link.children && props.link.children.length > 0) {
      setMenuVisible(false);
    }
  };

  const handleNavClick = (title: string, controlRegion?: string) => {
    setMenuVisible(false);

    trackPageAction(
      {
        controlText: title,
        controlType: 'Click',
        controlName: 'nav-menu-links',
        ...(!!controlRegion && { controlRegion }),
      },
      {
        reqData: {
          requirementID: 'ac-Das001',
          userStory: 'ACNBPT4-285',
        },
      },
    );
  };

  return (
    <div
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.navItemWrap}
    >
      {props.link.openExternal && (
        <a
          target="_blank"
          href={props.link.path}
          rel="noreferrer"
          className={styles.navLink}
          onClick={() => handleNavClick(t(`${props.link.title}`))}
          data-test-id={`${props.link.identifier}-link`}
        >
          {t(`${props.link.title}`)}

          {props.link.children && props.link.children.length > 0 && (
            <LeafIcon svg={caretDown} aria-label="navigation dropdown" />
          )}
        </a>
      )}

      {!props.link.openExternal && (
        <NavLink
          to={props.link.path}
          onClick={() => handleNavClick(t(`${props.link.title}`))}
          className={({ isActive }) =>
            isActive
              ? `${styles.navLink} ${styles.navLinkActive}`
              : `${styles.navLink}`
          }
          data-test-id={`${props.link.identifier}-link`}
        >
          {t(`${props.link.title}`)}

          {props.link.children && props.link.children.length > 0 && (
            <LeafIcon svg={caretDown} aria-label="navigation dropdown" />
          )}
        </NavLink>
      )}

      {props.link.children && (
        <ul
          className={
            isMenuVisible
              ? `${styles.navMenu} ${styles.navMenuVisible}`
              : `${styles.navMenu} leaf-u-is-vishidden`
          }
        >
          {props.link.children.map((child, index) => (
            <li key={`${props.link.identifier}-${index}`}>
              {child.openExternal ? (
                <a
                  className={styles.externalLink}
                  target="_blank"
                  href={child.path}
                  rel="noreferrer"
                  onClick={() =>
                    handleNavClick(
                      t(`${child.title}`),
                      t(`${props.link.title}`),
                    )
                  }
                >
                  {t(`${child.title}`)}
                  <LeafIcon svg={externalLinkIcon} aria-label="external link" />
                </a>
              ) : (
                <NavLink
                  className="enac-g-router-link"
                  to={child.path}
                  // eslint-disable-next-line sonarjs/no-identical-functions
                  onClick={() =>
                    handleNavClick(
                      t(`${child.title}`),
                      t(`${props.link.title}`),
                    )
                  }
                  data-test-id={`${props.link.identifier}-${index}-link`}
                >
                  {t(`${child.title}`)}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default MainNavigationItem;

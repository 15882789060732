import { useTranslation } from 'react-i18next';
import {
  LeafProgressMeter,
  LeafFieldNote,
  LeafHeading,
} from '@esi/leaf-web/lib/react';

import { validatePassword } from '@cigna/enac-provider-web/shared/util';
import styles from './password-strength-meter.module.scss';

export interface PasswordStrengthMeterProps {
  password: string;
  minLength: number;
  minUppercase?: number;
  minLowercase?: number;
  minNumbers?: number;
  minSymbols?: number;
  shouldShowRules?: boolean;
  shouldShowProgressMeter?: boolean;
  data?: {
    score: number;
    warnings: string;
    suggestions: string[];
  };
  validatePw?: boolean;
}

export function PasswordStrengthMeter({
  password,
  minLength,
  minUppercase = 1,
  minLowercase = 1,
  minNumbers = 1,
  minSymbols = 1,
  shouldShowRules = true,
  shouldShowProgressMeter = true,
  data,
  validatePw,
}: PasswordStrengthMeterProps) {
  const { t } = useTranslation();
  const maxProgressMeterValue = 4;

  const {
    isValidLength,
    isContainLowercase,
    isContainUppercase,
    isContainNumbers,
    isContainSymbols,
  } = validatePassword(password, {
    minUppercase,
    minLength,
    minLowercase,
    minNumbers,
    minSymbols,
  });

  const getPasswordStatus = (score: number): 'error' | 'success' => {
    if (score >= 3) {
      return 'success';
    }
    return 'error';
  };

  return (
    <div className={styles.container}>
      {shouldShowRules && (
        <>
          {shouldShowProgressMeter && (
            <LeafProgressMeter
              max={maxProgressMeterValue}
              value={data?.score || 0}
              status={getPasswordStatus(data?.score || 0)}
            />
          )}
          <div className="enac-g-margin-top-32">
            <LeafHeading
              tagVariant="h3"
              type="title-default"
              data-test-id="heading-password-strength"
            >
              {t('passwordStrengthMeter.passwordMustText')}
            </LeafHeading>
          </div>
          <LeafFieldNote
            {...(validatePw && {
              isError: !isValidLength,
              isSuccess: isValidLength,
            })}
            data-test-id="password-length-label"
          >
            {t('passwordStrengthMeter.checks.lengthLabel', {
              minLength,
            })}
          </LeafFieldNote>

          <LeafFieldNote
            {...(validatePw && {
              isError: !isContainLowercase,
              isSuccess: isContainLowercase,
            })}
            data-test-id="password-lowercase-variety-label"
          >
            {t('passwordStrengthMeter.checks.lowercaseLabel')}
          </LeafFieldNote>

          <LeafFieldNote
            {...(validatePw && {
              isError: !isContainUppercase,
              isSuccess: isContainUppercase,
            })}
            data-test-id="password-uppercase-variety-label"
          >
            {t('passwordStrengthMeter.checks.uppercaseLabel')}
          </LeafFieldNote>

          <LeafFieldNote
            {...(validatePw && {
              isError: !isContainNumbers,
              isSuccess: isContainNumbers,
            })}
            data-test-id="password-number-variety-label"
          >
            {t('passwordStrengthMeter.checks.numberLabel')}
          </LeafFieldNote>

          <LeafFieldNote
            {...(validatePw && {
              isError: !isContainSymbols,
              isSuccess: isContainSymbols,
            })}
            data-test-id="password-symbol-variety-label"
          >
            {t('passwordStrengthMeter.checks.specialCharacterLabel')}
          </LeafFieldNote>
          {data?.warnings && (
            <LeafFieldNote
              {...{
                isError: true,
              }}
              data-test-id="password-server-validation-label"
            >
              {data.warnings}
            </LeafFieldNote>
          )}
        </>
      )}
    </div>
  );
}

export default PasswordStrengthMeter;

import type { ColumnSort } from '@tanstack/react-table';
import type { StandardApiResponse } from '@cigna/enac-provider-web/shared/util';
import { useQuery } from '@cigna/shared/react/react-query-web-data-access';

export interface AttachmentsHistoryResponse extends StandardApiResponse {
  details?: AttachmentHistory;
}

export interface AttachmentHistory {
  attchmtHist: AttachmentHistoryItem[];
  count: number;
}

export interface AttachmentHistoryItem {
  attchmtFilePaths: string[];
  attchmtFiles: string[];
  clmId: string;
  memberId: string;
  origClmId: string;
  provderTin: string;
  referenceNum: string;
  status: string;
  submtdBy: string;
  submtdTs: string; // "2024-10-17T00:00:00"
}

export interface AttachmentsHistoryRequest {
  attchmTy:
    | 'US' // unsolicited
    | 'SO' // solicited
    | 'RC'; // reconsideration
  limit: number;
  offset: number;
  pitId?: string;
  sort: ColumnSort[];
  claimId?: string;
  referenceNum?: string;
}

type SortDirection = 'asc' | 'desc';
export interface attachmentHistorySort {
  [key: string]: SortDirection;
}
const sortFormatForApi = (sort: ColumnSort[]): attachmentHistorySort[] => {
  if (sort.length > 0) {
    return [
      {
        [sort[0].id]: sort[0].desc ? 'desc' : 'asc',
      },
    ];
  }

  return [];
};

export const useGetAttachmentsHistorySolicitedApi = ({
  attchmTy,
  pitId,
  claimId,
  limit,
  offset,
  sort,
}: AttachmentsHistoryRequest) =>
  useQuery<AttachmentsHistoryResponse>(
    {
      method: 'POST',
      url: '/attachment/v1/history',
      data: {
        attchmTy,
        pitId,
        claimId,
        limit,
        offset,
        sort: sortFormatForApi(sort),
      },
    },
    {
      queryKey: ['attachmentHistorySolicited', claimId],
      refetchOnWindowFocus: false,
    },
  );

export const useGetAttachmentsHistoryUnsolicitedApi = ({
  attchmTy,
  pitId,
  referenceNum,
  limit,
  offset,
  sort,
  claimId,
}: AttachmentsHistoryRequest) =>
  useQuery<AttachmentsHistoryResponse>(
    {
      method: 'POST',
      url: '/attachment/v1/history',
      data: {
        claimId,
        attchmTy,
        pitId,
        referenceNum,
        limit,
        offset,
        sort: sortFormatForApi(sort),
      },
    },
    {
      refetchOnWindowFocus: false,
    },
  );

import type { PropsWithChildren } from 'react';
import { LeafHeading } from '@esi/leaf-web/lib/react';
import styles from './card-group.module.scss';

export interface CardGroupProps {
  title?: string;
  cardType: 'link' | 'edit';
}

export function CardGroup(props: PropsWithChildren<CardGroupProps>) {
  return (
    <div data-test-id="card-group">
      {props.title && (
        <div className={styles.heading}>
          <LeafHeading tagVariant="h2" type="title-large">
            {props.title}
          </LeafHeading>
        </div>
      )}
      <div
        className={
          props.cardType === 'edit'
            ? styles.editContainer
            : styles.linkContainer
        }
      >
        {props.children}
      </div>
    </div>
  );
}

export default CardGroup;

import { useMemo } from 'react';
import type { EnvironmentConfig } from './environment-config';
import { useEnvironment } from './environment.context';

export function useAppLogoPaths(currEnvironment: EnvironmentConfig) {
  return useMemo(() => {
    const appLogoPath = `${currEnvironment.baseAppUrl}/assets/${currEnvironment.brandName}/images/logos/logo.png`;
    const appLogo2xPath = `${currEnvironment.baseAppUrl}/assets/${currEnvironment.brandName}/images/logos/logo@2x.png`;
    const appLogo3xPath = `${currEnvironment.baseAppUrl}/assets/${currEnvironment.brandName}/images/logos/logo@3x.png`;

    return [
      `${appLogoPath} 400w`,
      `${appLogo2xPath} 800w`,
      `${appLogo3xPath} 1200w`,
    ].join(',');
  }, [currEnvironment]);
}

export function useLandingImagePaths(currEnvironment: EnvironmentConfig) {
  return useMemo(() => {
    const landingImagePath = `${currEnvironment.baseAppUrl}/assets/${currEnvironment.brandName}/images/illustrations/landing-illustration.png`;
    const landingImage2xPath = `${currEnvironment.baseAppUrl}/assets/${currEnvironment.brandName}/images/illustrations/landing-illustration@2x.png`;
    const landingImage3xPath = `${currEnvironment.baseAppUrl}/assets/${currEnvironment.brandName}/images/illustrations/landing-illustration@3x.png`;

    return [
      `${landingImagePath} 400w`,
      `${landingImage2xPath} 800w`,
      `${landingImage3xPath} 1200w`,
    ].join(',');
  }, [currEnvironment]);
}

export function useThreeSizeImageSrc(imageName: string) {
  const { environment } = useEnvironment();

  return useMemo(() => {
    const imagePath = `${environment.baseAppUrl}/assets/${environment.brandName}/images/illustrations/${imageName}.png`;
    const image2xPath = `${environment.baseAppUrl}/assets/${environment.brandName}/images/illustrations/${imageName}@2x.png`;
    const image3xPath = `${environment.baseAppUrl}/assets/${environment.brandName}/images/illustrations/${imageName}@3x.png`;

    return [
      `${imagePath} 400w`,
      `${image2xPath} 800w`,
      `${image3xPath} 1200w`,
    ].join(',');
  }, [environment.baseAppUrl, environment.brandName, imageName]);
}

export function useOneSizeImageSrc(imageName: string) {
  const { environment } = useEnvironment();

  return useMemo(
    () =>
      `${environment.baseAppUrl}/assets/${environment.brandName}/images/illustrations/${imageName}.png`,
    [environment.baseAppUrl, environment.brandName, imageName],
  );
}

export const useAppImagePath = (path: string): string => {
  const { environment } = useEnvironment();
  return `${environment.baseAppUrl}${path}`;
};

export const useGlobalImagePath = (path?: string): string | undefined => {
  const { environment } = useEnvironment();
  if (!path) {
    return undefined;
  }
  return `${environment.baseDigitalEdgeUrl}${path}`;
};

export const useResponsiveImage = ({
  imageUri,
  small = '400w',
  medium = '800w',
  large = '1200w',
}: {
  imageUri: string;
  small?: string;
  medium?: string;
  large?: string;
}) => {
  const { environment } = useEnvironment();
  if (imageUri.length) {
    const defaultProtocolAndHost: string = environment.baseAppUrl as string;

    const smUrl = new URL(imageUri, defaultProtocolAndHost);
    smUrl.pathname = removeAtXFromEnd(smUrl.pathname);

    const mdUrl = new URL(smUrl);
    mdUrl.pathname = mdUrl.pathname.replace(/\./, '@2x.');

    const lgUrl = new URL(smUrl);
    lgUrl.pathname = lgUrl.pathname.replace(/\./, '@3x.');

    return `${smUrl.toString()} ${small}, ${mdUrl.toString()} ${medium}, ${lgUrl.toString()} ${large}`;
  }

  return imageUri;
};

const removeAtXFromEnd = (pathName: string) => {
  const arr = pathName.split('.');
  const extension = arr[arr.length - 1];
  const regexPattern = new RegExp(`@(2|3)x\\.${extension}$`);
  return pathName.replace(regexPattern, (match, group) =>
    group === '2' ? `.${extension}` : `.${extension.replace('x', '')}`,
  );
};

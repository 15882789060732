import type {
  ClaimAttachmentApiEndpoint,
  ClaimAttachmentType,
  FileMetaData,
  StandardApiResponse,
} from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export interface ClaimAttachmentsSubmitRequestBody {
  attachmentType: ClaimAttachmentType;
  attchmtCtrlNum: string;
  billgProvTin: string;
  claimId?: string;
  email?: string;
  files: FileMetaData[];
  patientControlNumber: string;
  patientId: string;
  rfaiCtrlNum: string;
  rndrProvNpi: string;
  serviceBeginDate: string;
  serviceEndDate?: string;
  serviceReferenceNumber: string;
  submittedBy: string;
}

export const usePostClaimAttachmentsSubmitApi = (
  apiEndpoint: ClaimAttachmentApiEndpoint,
) =>
  useMutation<StandardApiResponse, ClaimAttachmentsSubmitRequestBody>({
    method: 'POST',
    url: `/claims/attachments/v1/${apiEndpoint}/submit`,
  });

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import uploadIcon from '@esi/leaf-icons/lib/icons/System/Regular/Upload.svg?raw';
import LeafIcon from '@esi/leaf-icons/lib/react/LeafIcon';
import { LeafCard, LeafFieldNote, LeafHeading } from '@esi/leaf-web/lib/react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { usePostClaimAttachmentsMetadataApi } from '@cigna/enac-provider-web/shared/data-access';
import type {
  ClaimAttachmentApiEndpoint,
  ClaimAttachmentType,
  ClaimSignedUrlEndpoint,
} from '@cigna/enac-provider-web/shared/util';

import FileUploadList from '../file-upload-list/file-upload-list';
import styles from './file-upload.module.scss';
type fileSizeType = 'KB' | 'MB' | 'GB';

export interface FileUploadProps {
  attachmentType: ClaimAttachmentType;
  metadataApiEndpoint: ClaimAttachmentApiEndpoint;
  signedUrlApiEndpoint: ClaimSignedUrlEndpoint;
  serviceReferenceNumber: string;
  claimId?: string;
  fileAccept?: string[];
  allowedMultipleFiles?: boolean;
  maxFiles?: number;
  maxFileSize?: number;
  maxFileSizeType?: fileSizeType;
  onFilesSelected?: (files: UploadFile[]) => void;
  isError?: boolean;
  errorNote?: string;
  isDisabled?: boolean;
  uploadedFiles?: UploadFile[];
}

// Define the ref type
export interface UploadFileRef {
  reset: () => void;
}

export interface UploadFile {
  file: File;
  isValid: boolean;
  errorMsg: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const FileUpload = forwardRef<UploadFileRef, FileUploadProps>(
  (props, ref) => {
    const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>(
      props.uploadedFiles || [],
    );
    const [isSizeError, setIsSizeError] = useState<boolean>(false);
    const [isMaxFilesError, setMaxFilesError] = useState<boolean>(false);
    const [isTypeError, setIsTypeError] = useState<boolean>(false);
    const [dragging, setDragging] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
      undefined,
    );

    const { t } = useTranslation();

    const claimAttachmentsMetadataApi = usePostClaimAttachmentsMetadataApi(
      props.metadataApiEndpoint,
    );

    const resetUploadedFiles = () => {
      setUploadedFiles([]);
      setIsSizeError(false);
      setIsTypeError(false);
    };

    // Pass the ref to the useImperativeHandle hook
    useImperativeHandle(ref, () => ({
      reset: () => {
        resetUploadedFiles();
      },
    }));

    // Handle for file change by browse button
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputFile = event.target as HTMLInputElement;
      let newUploadedFfile = convertToUploadFiles(inputFile.files);
      newUploadedFfile = removeExistingFiles(newUploadedFfile);
      if (!!hadMaxFilesError(newUploadedFfile)) return;
      validateFiles([...uploadedFiles, ...newUploadedFfile]);
      setErrorMessage(undefined);
      handleUpdateAttachmentMetadata(newUploadedFfile);
    };

    // Handle for drag and drop files
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      let newUploadedFfile = convertToUploadFiles(event.dataTransfer.files);
      setDragging(false);
      newUploadedFfile = removeExistingFiles(newUploadedFfile);
      if (!!hadMaxFilesError(newUploadedFfile)) return;
      validateFiles([...uploadedFiles, ...newUploadedFfile]);
      setErrorMessage(undefined);
      handleUpdateAttachmentMetadata(newUploadedFfile);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);
    };

    // Removing already uploading file(s)
    const removeExistingFiles = (newFiles: UploadFile[]): UploadFile[] =>
      newFiles.filter(
        (f) => !uploadedFiles.find((u) => u.file.name === f.file.name),
      );

    const handleRemoveFile = (file: File) => {
      const updatedFiles = uploadedFiles.filter(
        (f) => f.file.name.toLowerCase() !== file.name.toLocaleLowerCase(),
      );

      setUploadedFiles(updatedFiles);

      validateFiles(updatedFiles);
      setErrorMessage(undefined);
    };

    const convertToUploadFiles = (fileList: FileList | null): UploadFile[] => {
      let uploadedFileList: UploadFile[] = [];
      if (fileList && fileList.length > 0) {
        const newFiles = Array.from(fileList);

        uploadedFileList = newFiles.map((file) => ({
          file,
          isValid: true,
          errorMsg: '',
        }));
      }
      return uploadedFileList;
    };

    const hadMaxFilesError = (newFiles: UploadFile[]): boolean => {
      // check if the provided count prop is less than uploaded count of files
      if (
        props.maxFiles &&
        props.maxFiles < newFiles.length + uploadedFiles.length
      ) {
        setMaxFilesError(true);
        return true;
      }
      return false;
    };
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const validateFiles = (fileList: UploadFile[]) => {
      setIsTypeError(false);
      setIsSizeError(false);
      setMaxFilesError(false);
      if (fileList.length > 0) {
        for (const uf of fileList) {
          // check if some uploaded file is not in one of the allowed formats
          if (
            props.fileAccept &&
            !props.fileAccept.some((format) =>
              uf.file.name.toLowerCase().endsWith(format.toLowerCase()),
            )
          ) {
            uf.isValid = false;
            uf.errorMsg = t('fileUpload.errorMsg.fileAccept', {
              fileAccept: props.fileAccept.join(', '),
            });
            setIsTypeError(true);
          }

          // Validating file size
          if (
            props.maxFileSize &&
            getFileSizeByType(
              uf.file.size,
              props.maxFileSizeType ? props.maxFileSizeType : 'MB',
            ) > props.maxFileSize
          ) {
            uf.isValid = false;
            uf.errorMsg = t('fileUpload.errorMsg.fileSize', {
              fileSize: props.maxFileSize,
            });
            setIsSizeError(true);
          }
        }
      }
    };

    const getFileSizeByType = (bytes: number, type: fileSizeType): number => {
      switch (type) {
        case 'KB':
          return bytes / Math.pow(1024, 1);
        case 'MB':
          return bytes / Math.pow(1024, 2);
        case 'GB':
          return bytes / Math.pow(1024, 3);
      }
    };

    // Step4: Update claim attachment metadata in cosmose DB
    const handleUpdateAttachmentMetadata = (newFiles: UploadFile[]) => {
      if (newFiles.length > 0) {
        claimAttachmentsMetadataApi.mutate(
          {
            serviceReferenceNumber: props.serviceReferenceNumber,
            attachmentType: props.attachmentType,
            claimId: props.claimId,
            files: newFiles.map((u) => ({
              name: u.file.name,
              size: u.file.size,
              type: u.file.type,
            })),
          },
          {
            onSuccess: () => {
              setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
            },
            onError: (er) => {
              setErrorMessage(
                er.response?.data.metadata?.outcome?.message || er.message,
              );
            },
          },
        );
      }
    };

    useEffect(
      () => {
        props.onFilesSelected && props.onFilesSelected(uploadedFiles);
      },
      // eslint-disable-next-line  react-hooks/exhaustive-deps
      [uploadedFiles],
    );

    return (
      <section className={styles.fileUploadSection}>
        <div>
          <LeafFieldNote
            {...{
              isError: isTypeError ? true : undefined,
            }}
            data-test-id="file-upload-file-type"
          >
            <Trans
              i18nKey="fileUpload.supportText"
              values={{ acceptTypes: props.fileAccept?.join(', ') }}
              components={{
                bold: <strong />,
              }}
            />
          </LeafFieldNote>
        </div>
        <div>
          <LeafFieldNote
            {...{
              isError: isSizeError ? true : undefined,
            }}
            data-test-id="file-upload-file-size"
          >
            <Trans
              i18nKey="fileUpload.fileSizeText"
              values={{ fileSize: props.maxFileSize }}
              components={{
                bold: <strong />,
              }}
            />
          </LeafFieldNote>
        </div>
        <div>
          <LeafFieldNote
            {...{
              isError: isMaxFilesError ? true : undefined,
            }}
            data-test-id="file-upload-file-size"
          >
            <Trans
              i18nKey="fileUpload.fileNumberText"
              values={{ maxFiles: props.maxFiles }}
              components={{
                bold: <strong />,
              }}
            />
          </LeafFieldNote>
        </div>
        <div
          className={styles.fileUploadContainer}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          data-test-id="drag-and-drop-container"
        >
          <LeafCard
            className={`${styles.fileUpload} ${
              props.isError ? styles.error : ''
            }`}
            align="center"
          >
            {dragging && (
              <div
                className={styles.draggingOverlay}
                onDragEnter={handleDragOver}
                onDragLeave={handleDragLeave}
                onMouseLeave={handleDragLeave}
                data-test-id="drag-and-drop-overlay"
              >
                <LeafHeading type="title-default">
                  {t('fileUpload.draggingText')}
                </LeafHeading>
              </div>
            )}
            <div className={styles.iconContainer}>
              <LeafIcon
                svg={uploadIcon}
                height={32}
                width={32}
                aria-label="upload"
              />
            </div>
            <p> {t('fileUpload.dragFileText')}</p>
            <p>
              {t('fileUpload.orText')}{' '}
              <input
                type="file"
                hidden
                id={`browse-${props.serviceReferenceNumber}`}
                onChange={(event) => {
                  handleFileChange(event);
                  event.target.value = '';
                }}
                accept={props.fileAccept?.join(',')}
                multiple={props.allowedMultipleFiles}
                data-test-id="input-file"
                disabled={props.isDisabled}
              />
              <label
                htmlFor={`browse-${props.serviceReferenceNumber}`}
                className={`${styles.browseBtn} ${
                  props.isDisabled ? styles.browseBtnDisabled : ''
                }`}
                data-test-id="browse-btn"
              >
                {t('fileUpload.browseBtn')}
              </label>
            </p>
          </LeafCard>
        </div>
        <FileUploadList
          uploadedFiles={uploadedFiles}
          serviceReferenceNumber={props.serviceReferenceNumber}
          attachmentType={props.attachmentType}
          metadataApiEndpoint={props.metadataApiEndpoint}
          signedUrlApiEndpoint={props.signedUrlApiEndpoint}
          onRemoveFile={handleRemoveFile}
        />
        {props.isError && props.errorNote && (
          <LeafFieldNote
            isError
            aria-live="polite"
            data-test-id="file-upload-error-note"
            className="enac-g-margin-top-16"
          >
            {props.errorNote}
          </LeafFieldNote>
        )}

        {errorMessage && (
          <LeafFieldNote
            isError
            aria-live="polite"
            data-test-id="file-upload-error-msg"
            className="enac-g-margin-top-16"
          >
            {errorMessage}
          </LeafFieldNote>
        )}
      </section>
    );
  },
);

export default FileUpload;

import type {
  AttachmentResponse,
  ClaimSignedUrlEndpoint,
  StandardApiResponse,
} from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export interface SignedUrlClaimAttachmentsRequestBody {
  name: string;
}

export interface SignedUrlClaimAttachmentsResponse extends StandardApiResponse {
  result: AttachmentResponse;
}

export const usePostClaimAttachmentsSignedUrlApi = (
  apiEndpoint: ClaimSignedUrlEndpoint,
) =>
  useMutation<
    SignedUrlClaimAttachmentsResponse,
    SignedUrlClaimAttachmentsRequestBody
  >({
    method: 'POST',
    url: `/upload/claims/${apiEndpoint}/signedurl`,
  });

import { useCallback } from 'react';
import Countdown, { type CountdownRenderProps } from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { LeafHeading, LeafModal, LeafButton } from '@esi/leaf-web/lib/react';
import { useEnvironment } from '@cigna/shared/react/environment-provider-util';

interface SessionTimeoutModalProps {
  modalInstanceKey: string | false;
  handleCloseModal: () => void;
  idleTimeout: number;
  timeRemainingMs: number;
}

export const SessionTimeoutModal = ({
  handleCloseModal,
  modalInstanceKey = false,
  idleTimeout,
  timeRemainingMs,
}: SessionTimeoutModalProps) => {
  const { logout } = useAuth0();
  const { environment } = useEnvironment();
  const { t } = useTranslation();

  const isModalOpen = !!modalInstanceKey;

  const idleTimeoutMin = Math.ceil(idleTimeout / 60000);

  const handleSessionTimeout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: environment.auth0.redirect_uri,
      },
    });
  }, [environment.auth0.redirect_uri, logout]);

  return (
    <LeafModal isActive={isModalOpen} notDismissible>
      <LeafHeading slot="header" type="title-large">
        {t('timeout.modal.header')}
      </LeafHeading>
      <div>
        <div>
          <p data-test-id="modalContent">
            {t('timeout.modal.body1')} {idleTimeoutMin}{' '}
            {t('timeout.modal.body2')} <br />
            {t('timeout.modal.body3')}{' '}
            <span>
              {isModalOpen && (
                <>
                  <Countdown
                    date={Date.now() + timeRemainingMs}
                    renderer={renderer}
                    key={modalInstanceKey}
                  />
                  {'.'}
                </>
              )}
            </span>
          </p>
        </div>
      </div>
      <div slot="footer" className="enac-g-button-group">
        <LeafButton
          variant="primary"
          size="lg"
          text={t('timeout.modal.btnStay')}
          onClick={handleCloseModal}
          className="enac-g-margin-right-lg"
        />
        <LeafButton
          variant="secondary"
          size="lg"
          text={t('timeout.modal.btnLeave')}
          onClick={handleSessionTimeout}
        />
      </div>
    </LeafModal>
  );
};

function renderer({ minutes, formatted }: CountdownRenderProps) {
  return (
    <span>
      {minutes}:{formatted.seconds} {minutes > 0 ? 'minutes' : 'seconds'}
    </span>
  );
}

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  LeafButton,
  LeafHeading,
  LeafFieldNote,
} from '@esi/leaf-web/lib/react';

import { PageWrapper } from '@cigna/enac-provider-web/shared/ui';

import { useTrackPageAction } from '@cigna/shared/react/analytics-util';
// eslint-disable-next-line @nx/enforce-module-boundaries
import EvernorthLogo from '../../../../../../shared/angular/assets-ui/images/evn_logo_hs_r_rgb_tempermint.svg';

import styles from './welcome.module.scss';

export function Welcome() {
  const { t } = useTranslation();
  const { isLoading, loginWithRedirect, error } = useAuth0();

  const trackPageAction = useTrackPageAction();

  const [searchParams] = useSearchParams();
  const hasCallbackParam =
    searchParams.has('code') && searchParams.has('state');

  return (
    <PageWrapper
      variation="custom"
      disablePageWrap
      disablePadding
      pageTitle="index"
      analytics={{
        pageData: {
          pageName: 'Guest login',
          pageSection: 'Login',
          pageType: 'Access',
          pageAuthenticationType: 'Unauthenticated',
          site: 'ENAC',
        },
        reqData: {
          requirementID: 'pv-Log001',
          userStory: 'ACNBPT4-282',
        },
      }}
    >
      <div className={styles.container}>
        <div
          className="enac-g-page-wrap"
          style={{ height: '100%', display: 'flex' }}
        >
          <div className={styles.contentSection}>
            <LeafHeading className="leaf-u-is-vishidden" tagVariant="h1">
              {t('landing.screenReaderTitle')}
            </LeafHeading>
            <div className={styles.titleArea}>
              <p>{t('landing.title')}</p>
              <img
                className={styles.logo}
                src={EvernorthLogo}
                alt="Evernorth Health Services"
                width={'340'}
                height={'65'}
              />
            </div>

            <div className={styles.bodytextArea}>
              <p>{t('landing.bodyText')}</p>
            </div>

            <div className={styles.buttonArea}>
              <LeafButton
                data-test-id="btn-login"
                text={`${t('landing.loginBtn')}`}
                size="lg"
                disabled={
                  (isLoading || hasCallbackParam) && error === undefined
                }
                onClick={() => {
                  trackPageAction(
                    {
                      controlText: 'Log in',
                      controlType: 'Click',
                      controlName: 'guest-page-login-button',
                    },
                    {
                      reqData: {
                        requirementID: 'ac-Log001',
                        userStory: 'ACNBPT4-282',
                      },
                    },
                  );
                  loginWithRedirect({
                    appState: { prompt: 'login', returnTo: '/dashboard' },
                  });
                }}
              />
              {error?.message && (
                <div className="enac-g-margin-top-16">
                  <LeafFieldNote isError data-test-id="auth0-error-field">
                    {error.message}
                  </LeafFieldNote>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

export default Welcome;

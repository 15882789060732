/* eslint-disable sonarjs/no-duplicate-string */
import { metadata200Mock } from '@cigna/enac-provider-web/test-util';
import { createMockService } from '@cigna/shared/mock-services-util';
import type { GetUserProfileResponse } from '../src/lib/api/use-get-user-profile-api';

export const mockGetUserProfile: GetUserProfileResponse = {
  metadata: {
    serviceReferenceId: '0000000A-000B-000C-000D-00000000000E',
    outcome: {
      status: 200,
      type: 'OK',
      message: 'Success',
      code: 0,
      additionalDetails: [],
    },
  },
  identity: {
    userId: '123456A',
    idpUserId: '00u7uz6bu71l4n35h1d7',
    email: 'harshalbhai.patel@cigna.com',
    employeeId: 'EMP11111111111111111111111111',
    firstName: 'Harshal',
    middleName: 'B',
    lastName: 'Patel',
    phone: '2465184183',
    phoneExt: '12345',
    mobilePhone: '2465184184',
    lob: 'MEDBH',
    addressLine1: 'Jagielski St',
    addressLine2: 'Penthouse Suite 1',
    city: 'Robertville',
    state: 'CT',
    zip: '12345',
    pwdLastChangedTS: '2024-03-01T00:00:00.000Z',
    attestations: ['User Agreement', 'Email Verified'],
    roles: ['Primary Admin', 'Contracting'],
    entitlements: [
      'Watch',
      'EFT',
      'User Access',
      'Practice Information',
      'Documents',
      'Claims',
    ],
    tins: [
      {
        number: '111111',
        name: 'Family Medical Practice',
        entitlements: [
          {
            functions: ['Update', 'Renew', 'Sign'],
            name: 'Documents',
          },
          {
            functions: [],
            name: 'User Access',
          },
        ],
        contracted: true,
        sanctioned: false,
        hasComrcl: false,
        hasGovrnm: false,
        lob: 'MEDBH',
      },
      {
        number: '976846432',
        name: 'Test',
        entitlements: [
          {
            functions: ['Attachment', 'Reconsideration', 'Appeal'],
            name: 'Claims',
          },
          {
            functions: ['New'],
            name: 'Referrals',
          },
          {
            functions: [],
            name: 'User Access',
          },
        ],
        contracted: true,
        sanctioned: false,
        hasComrcl: false,
        hasGovrnm: false,
        lob: 'MEDBH',
      },
      {
        number: '222222',
        name: 'Dental',
        entitlements: [
          {
            functions: [],
            name: 'User Access',
          },
        ],
        contracted: true,
        sanctioned: false,
        hasComrcl: false,
        hasGovrnm: false,
        lob: 'MEDBH',
      },
      {
        number: '444444',
        name: '',
        entitlements: [
          {
            functions: [],
            name: 'User Access',
          },
        ],
        contracted: true,
        sanctioned: false,
        hasComrcl: false,
        hasGovrnm: false,
        lob: 'MEDBH',
      },
    ],
  },
};

const handlers = createMockService({
  method: 'get',
  url: '*/identity/v1/profile/user',
  callback: (_, res, ctx) =>
    res(
      ctx.json({
        metadata: metadata200Mock,
        identity: mockGetUserProfile.identity,
      }),
    ),
});

export default handlers;

import { useQuery } from '@cigna/shared/react/react-query-web-data-access';

interface GetContractsResponse {
  metadata: {
    outcome: {
      message: string;
      status: number;
      code: number;
      type: string;
    };
  };
  contractList?: ContractItem[];
}

export interface ContractItem {
  tin: string;
  contractStatus: 'COMPLETED' | 'PENDING';
  title: string;
  contractUrl: string;
  sentDate: string;
  signedIndicator: 'Y' | 'N';
  signedDate: string;
}

export const useGetContractsApi = () =>
  useQuery<GetContractsResponse>(
    {
      url: `/provider/v1/contracts/list`,
      method: 'GET',
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { withLDProvider, basicLogger } from 'launchdarkly-react-client-sdk';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import { environment } from './environments/environment';

const LDProvider = withLDProvider({
  clientSideID: environment.launchDarkly?.clientSideID as string,
  options: {
    bootstrap: 'localStorage',
    diagnosticOptOut: true,
    sendEvents: true,
    logger: environment.debug
      ? basicLogger({ level: 'info' })
      : basicLogger({ level: 'none' }),
  },
})(App);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <LDProvider />
    </BrowserRouter>
  </StrictMode>,
);

import { LeafBadge } from '@esi/leaf-web/lib/react';
import type { LeafBadge as LeafBadgeType } from '@esi/leaf-web/lib/wc/components/badge/badge';
import { AttachmentStatus } from '@cigna/enac-provider-web/shared/util';

export interface AttachmentStatusBadgeProps {
  status: string;
}

export function AttachmentStatusBadge(props: AttachmentStatusBadgeProps) {
  const attachmentStatus = props.status;

  let badgeStatus: LeafBadgeType['status'] = 'neutral';
  const badgeVariant: LeafBadgeType['variant'] = 'subtle';

  let badgeText = props.status;

  if (attachmentStatus === AttachmentStatus.Matched) {
    badgeStatus = 'success';
    badgeText = 'ACCEPTED';
  } else if (attachmentStatus === AttachmentStatus.Unmatched) {
    badgeStatus = 'neutral';
    badgeText = 'UPLOADED';
  } else if (attachmentStatus === AttachmentStatus.Rejected) {
    badgeStatus = 'error';
    badgeText = 'REJECTED';
  }

  return (
    <LeafBadge
      text={badgeText}
      status={badgeStatus}
      variant={badgeVariant}
    ></LeafBadge>
  );
}

export default AttachmentStatusBadge;

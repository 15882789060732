import { initReactI18next } from 'react-i18next';
import i18n, {
  type ResourceLanguage,
  type ResourceKey,
  type Resource,
} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import ResourcesToBackend from 'i18next-resources-to-backend';
import merge from 'lodash/merge';
import { parseTranslationResourcesForAnalytics } from '@cigna/shared/analytics/core';
import {
  i18nRequest,
  i18nParseLoadPayload,
  i18nLoadPath,
  i18nMergeClientTranslations,
  defaultNamespaceResolver,
} from './i18-backend';

const normalizeTranslationsToResourceAndMergeClientTranslations = (
  translation: object,
  clientId: string | undefined,
  nameSpaceToAppend?: string,
) => {
  let resources: Resource = { ...translation };

  // ensure first level is a language key
  const isFirstLevelLanguages =
    typeof resources.en !== 'undefined' ||
    typeof resources['en-us'] !== 'undefined' ||
    typeof resources['en-US'] !== 'undefined';
  if (!isFirstLevelLanguages) {
    resources = { en: resources };
  }

  // normalize language keys
  resources = Object.fromEntries(
    Object.entries(resources).map(([lang, trans]) => [
      normalizeLanguageKey(lang),
      trans,
    ]),
  );

  // 2nd level is namespace, insert if needed
  if (nameSpaceToAppend) {
    resources = Object.fromEntries(
      Object.entries(resources).map(([lang, trans]) => [
        lang,
        { [nameSpaceToAppend]: trans },
      ]),
    );
  }

  // merge client translations
  Object.entries(resources).forEach(([lang, namespaceTranslations]) =>
    Object.entries(namespaceTranslations).forEach(
      ([namespace, trans]) =>
        (resources[lang][namespace] = i18nMergeClientTranslations(
          trans as ResourceLanguage,
          clientId,
        )),
    ),
  );

  const supportedLngs = Object.keys(resources);
  const defaultEnglishKey = normalizeLanguageKey('en');

  parseTranslationResourcesForAnalytics(resources, defaultEnglishKey);

  // ensure english is a fallback for missing keys in other languages (deep keys needed help)
  supportedLngs.forEach((lang) => {
    if (lang !== defaultEnglishKey) {
      resources[lang] = merge(
        {},
        resources[defaultEnglishKey],
        resources[lang],
      );
    }
  });

  const ns = nameSpaceToAppend
    ? [nameSpaceToAppend]
    : Object.keys(resources[defaultEnglishKey]).filter(
        (ns2) => ns2 !== '$comment',
      );

  return {
    resources,
    helperProps: {
      supportedLngs,
      fallbackLng: defaultEnglishKey,
      lng: supportedLngs.length === 1 ? supportedLngs[0] : undefined,
      ns,
      defaultNS: ns[0],
      detection: {
        convertDetectedLanguage: normalizeLanguageKey,
        lookupCookie: 'lang',
        order: [
          'querystring',
          'cookie',
          // 'localStorage', // localstorage does not work for some reason. Should investigate
          'sessionStorage',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
        ],
      },
    },
  };
};

// All language keys will be ISO 639 compliant - two letter abbreviations
export const normalizeLanguageKey = (languageKey: string) =>
  languageKey.toLowerCase().replace('-us', '');

export const createi18nInstance = <T extends Record<string, ResourceKey>>(
  nameSpace: string,
  translations: T | Record<string, T>,
  debug: boolean = false,
  clientId?: string,
) => {
  const i18nInstance = i18n.createInstance();
  const { resources, helperProps } =
    normalizeTranslationsToResourceAndMergeClientTranslations(
      translations,
      clientId,
      nameSpace,
    );

  i18nInstance
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug,
      ...helperProps,
      resources,
    });

  return i18nInstance;
};

export const createi18nBackendInstance = <
  T extends Record<string, ResourceKey>,
>(
  nameSpace: string,
  manifestInstance: string,
  baseUrl: string,
  translations: T,
  options?: {
    debug?: boolean;
    clientId?: string;
    namespaceResolver?: typeof defaultNamespaceResolver;
  },
) => {
  const { debug, clientId, namespaceResolver } = options ?? {
    debug: false,
    namespaceResolver: defaultNamespaceResolver,
  };

  const i18nInstance = i18n.createInstance();
  const { resources, helperProps } =
    normalizeTranslationsToResourceAndMergeClientTranslations(
      translations,
      clientId,
      nameSpace,
    );

  i18nInstance
    .use(LanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      debug,
      initImmediate: false,
      ...helperProps,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      backend: {
        backends: [HttpBackend, ResourcesToBackend(resources)],
        backendOptions: [
          {
            request: i18nRequest(resources),
            parseLoadPayload: i18nParseLoadPayload(resources),
            loadPath: i18nLoadPath(
              manifestInstance,
              baseUrl,
              namespaceResolver,
            ),
          },
        ],
      },
    });

  return i18nInstance;
};

export const createi18nCombinedBackendInstance = <
  T extends Record<string, ResourceKey>,
>(
  nameSpaceTranslations: { [key: string]: T },
  manifestInstance: string,
  baseUrl: string,
  options?: {
    debug?: boolean;
    clientId?: string;
    namespaceResolver?: typeof defaultNamespaceResolver;
  },
) => {
  const { debug, clientId, namespaceResolver } = options ?? {
    debug: false,
    namespaceResolver: defaultNamespaceResolver,
  };

  const { resources, helperProps } =
    normalizeTranslationsToResourceAndMergeClientTranslations(
      nameSpaceTranslations,
      clientId,
    );
  const i18nInstance = i18n.createInstance();

  i18nInstance
    .use(LanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      debug,
      returnNull: false,
      initImmediate: false,
      ...helperProps,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      backend: {
        backends: [HttpBackend, ResourcesToBackend(resources)],
        backendOptions: [
          {
            request: i18nRequest(resources),
            parseLoadPayload: i18nParseLoadPayload(resources),
            loadPath: i18nLoadPath(
              manifestInstance,
              baseUrl,
              namespaceResolver,
            ),
          },
        ],
      },
    });

  return i18nInstance;
};

export const createI18nFallbackInstance = () => {
  const i18nFallback = i18n.createInstance();
  i18nFallback.init();
  return i18nFallback;
};

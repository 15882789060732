import {
  type ComponentProps,
  useState,
  forwardRef,
  useRef,
  useEffect,
} from 'react';

import { type LeafDomEvent, LeafField } from '@esi/leaf-web/lib/react';
import type { LeafField as LeafFieldElement } from '@esi/leaf-web/lib/wc/components/field/field';

import styles from './typeahead-dropdown.module.scss';

export interface DropdownOption {
  label: string;
  value: string;
}

type Ref = LeafFieldElement;

interface TypeaheadDropdownProps
  extends Omit<ComponentProps<typeof LeafField>, 'type'> {
  dynamicOptions?: DropdownOption[];
  staticOptions?: DropdownOption[];
  onInputChange?: (text: string) => void;
  onSelectionChange: (value: DropdownOption) => void;
}

export const LeafTypeaheadDropdown = forwardRef<Ref, TypeaheadDropdownProps>(
  (
    {
      staticOptions,
      dynamicOptions,
      onSelectionChange,
      onInputChange,
      ...rest
    },
    leafFieldRef,
  ) => {
    const [showDropdown, setDropdownVisible] = useState<boolean>(
      (dynamicOptions && dynamicOptions.length > 0) || false,
    );
    const [inputValue, setInputValue] = useState<string | undefined>('');
    const [filteredSuggestions, updateFilteredSuggestions] = useState<
      DropdownOption[]
    >([]);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const suggestions = dynamicOptions || filteredSuggestions;

    useEffect(() => {
      if (dynamicOptions?.length) {
        setDropdownVisible(true);
      }
    }, [dynamicOptions?.length]);

    useEffect(() => {
      const checkIfClickedOutside = (event: MouseEvent) => {
        if (
          showDropdown &&
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          escapeDropdown();
        }
      };

      document.addEventListener('mousedown', checkIfClickedOutside);

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside);
      };
    }, [showDropdown]);

    const escapeDropdown = () => {
      setDropdownVisible(false);
      setInputValue('');
      onSelectionChange({ label: '', value: '' });
    };

    const handleInputValueChange = (
      e: LeafDomEvent<LeafFieldElement, LeafFieldElement>,
    ) => {
      if (staticOptions) {
        const matchValue = e.target.value.toLowerCase();
        updateFilteredSuggestions(
          staticOptions.filter((option) =>
            option.label.toLowerCase().match(matchValue),
          ),
        );
        setDropdownVisible(true);
      }

      if (onInputChange) {
        onInputChange(e.target.value);
      }

      if (suggestions.length > 0) {
        setDropdownVisible(true);
      }
    };

    const handleOptionSelected = (option: DropdownOption) => {
      onSelectionChange(option);
      setDropdownVisible(false);
    };

    const focusNext = () => {
      if (!suggestions.length) {
        return false;
      }

      const focused = document
        .getElementById('typeahead-suggestions')
        ?.querySelector(':focus');

      if (focused) {
        const nextItem = focused.parentElement?.nextSibling?.firstChild as
          | HTMLButtonElement
          | undefined;

        if (nextItem) {
          nextItem.focus();
        }
      } else {
        setDropdownVisible(true);
        document
          .querySelectorAll('#typeahead-suggestions > div')[0]
          .querySelector('button')
          ?.focus();
      }
    };

    const focusPrev = () => {
      if (!suggestions.length) {
        return false;
      }

      const focused = document
        .getElementById('typeahead-suggestions')
        ?.querySelector(':focus');

      if (focused) {
        const prevItem = focused.parentElement?.previousSibling?.firstChild as
          | HTMLButtonElement
          | undefined;

        if (prevItem) {
          prevItem.focus();
        } else {
          // in theory would be cool to hide suggestions and focus back on input but not sure way to do it since leaf
          // doesnt expose focus method and cant pierce shadow dom
        }
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      const keyCode = event.key;

      if (showDropdown) {
        if (keyCode === 'ArrowDown') {
          event.preventDefault();
          focusNext();
        }
        if (keyCode === 'ArrowUp') {
          event.preventDefault();
          focusPrev();
        }
        if (keyCode === 'Escape' || keyCode === 'Tab') {
          escapeDropdown();
        }
      }
    };

    return (
      <div
        className={styles.container}
        ref={wrapperRef}
        onKeyDown={(e) => handleKeyDown(e as any)}
      >
        <LeafField
          {...rest}
          onChange={handleInputValueChange}
          ref={leafFieldRef}
          autocomplete="off"
          aria-autocomplete="list"
          aria-expanded={showDropdown}
          aria-haspopup="listbox"
          aria-controls="typeahead-suggestions"
        />
        {showDropdown && suggestions.length >= 1 && (
          <div
            className={styles.suggestionsPanel}
            id="typeahead-suggestions"
            role="listbox"
            data-test-id="typeahead-suggestions"
          >
            {suggestions.map((option) => (
              <div role="option" key={option.value}>
                <button
                  className={styles.item}
                  onClick={() => handleOptionSelected(option)}
                >
                  {option.label}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  },
);

import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import noop from 'lodash/noop';

type Auth0LogoutOptions = Required<
  Parameters<ReturnType<typeof useAuth0>['logout']>
>[0];

interface LogoutOptions extends Auth0LogoutOptions {
  isCausedByError?: boolean;
}

interface ISubscriptionOptions {
  shouldCallOnError?: boolean;
}

type TAsyncMethod<T = void> = () => Promise<T>;
type TMethod<T = void> = () => T;

interface ISubscriptionRecord {
  fn: TAsyncMethod | TMethod;
  options?: ISubscriptionOptions;
}

/**
 * have this outside the useLogout fn so that
 * each call to useLogout uses the same
 * subscription list object.
 */
const onLogoutSubscriptions = new Set<ISubscriptionRecord>();

export function useLogout() {
  const { logout: auth0Logout } = useAuth0();

  /**
   * @returns Function to unsubscribe from the logout call
   * @example
   *   const {subscribe} = useLogout();
   *   useEffect(
   *     () => subscribe(handler),
   *     [handler, subscribe]
   *   );
   */
  const subscribeToLogout = useCallback(
    (handler: ISubscriptionRecord['fn'], options?: ISubscriptionOptions) => {
      const subscriptionRecord = { fn: handler, options };
      onLogoutSubscriptions.add(subscriptionRecord);
      return () => {
        onLogoutSubscriptions.delete(subscriptionRecord);
      };
    },
    [],
  );

  const logout = useCallback(
    (options: LogoutOptions = {}) => {
      const { isCausedByError = false, ...rest } = options;

      const promiseList = [...onLogoutSubscriptions].map((record) => {
        const { fn, options: _opt = {} } = record;
        const { shouldCallOnError = true } = _opt;
        let promise = Promise.resolve();
        if (isCausedByError && !Boolean(shouldCallOnError)) {
          promise = Promise.resolve();
        } else {
          const _promise = fn();
          promise = _promise instanceof Promise ? _promise : Promise.resolve();
        }

        promise.finally(() => onLogoutSubscriptions.delete(record)).catch(noop);

        return promise;
      });

      return Promise.allSettled(promiseList).then(async () => {
        window.sessionStorage.removeItem('hasLoggedIn');
        window.sessionStorage.removeItem('searchMatchDefaultLocation');

        return auth0Logout(rest);
      });
    },
    [auth0Logout, subscribeToLogout],
  );

  return {
    logout,
    subscribe: subscribeToLogout,
  };
}

export default useLogout;

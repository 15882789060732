/* eslint-disable sonarjs/no-duplicate-string */
import type { StandardApiResponse } from '@cigna/enac-provider-web/shared/util';
import { useQuery } from '@cigna/shared/react/react-query-web-data-access';

export interface Role {
  role: UserRole;
  label: string;
  description: string;
  enabled: boolean;
  roleRequired?: UserRole;
  subRoles: UserRole[];
}

export const allUserRoles: Role[] = [
  {
    role: 'Contracting',
    label: 'Contracting',
    description: 'This role can view and sign contracts.',
    enabled: true,
    subRoles: [],
  },
  {
    role: 'Front Office',
    label: 'Front office',
    description:
      'This role is view-only. Front office roles can not take any action on claims or pre-certifications.',
    enabled: true,
    subRoles: [],
  },
  {
    role: 'Back Office Billing Claims',
    label: 'Back office claims',
    description:
      'This role can view claims and take actions such as uploading attachments or submitting claim reconsiderations.',
    enabled: true,
    subRoles: ['Front Office'],
  },
  {
    role: 'Back Office Precertification',
    label: 'Back office precertification',
    description: '',
    enabled: false,
    subRoles: ['Front Office'],
  },
  {
    role: 'Secondary Admin',
    label: 'Secondary administrator',
    description:
      'This role can view claims and add front or back office roles. There is no limit on the number of secondary administrators per TIN. This role will have the same access as front and back office roles.',
    enabled: true,
    roleRequired: 'Primary Admin',
    subRoles: [
      'Front Office',
      'Back Office Billing Claims',
      // 'Back Office Precertification',
    ],
  },
  {
    role: 'Primary Admin',
    label: 'Primary administrator',
    description:
      'This role can view claims and is responsible for adding all users to be invited to register for portal access. Fifteen primary administrators are allowed per TIN.',
    enabled: true,
    roleRequired: 'Primary Admin',
    subRoles: [
      'Front Office',
      'Back Office Billing Claims',
      // 'Back Office Precertification',
      'Secondary Admin',
    ],
  },
];

export const mapUserRoleToLabel = (role: string): string =>
  allUserRoles.find((r) => r.role === role)?.label || role;

export const getUserRoleFromValue = (role: string): Role | undefined =>
  allUserRoles.find((r) => r.role === role);

export interface GetUserProfileResponse extends StandardApiResponse {
  identity: IdentityData;
}
export interface IdentityData {
  addressLine1: string;
  addressLine2: string;
  city: string;
  email: string;
  employeeId: string;
  firstName: string;
  idpUserId: string;
  lastName: string;
  lob: string;
  middleName: string;
  mobilePhone: string;
  phone: string;
  phoneExt: string;
  pwdLastChangedTS: string;
  state: string;
  userId: string;
  zip: string;
  attestations: string[];
  roles: UserRole[];
  entitlements: UserEntitlement[];
  tins: TinData[];
}

export type UserRole =
  | 'Primary Admin'
  | 'Secondary Admin'
  | 'Back Office Billing Claims'
  | 'Back Office Precertification'
  | 'Front Office'
  | 'Contracting';

export type UserEntitlement =
  | 'Watch'
  | 'EFT'
  | 'User Access'
  | 'Practice Information'
  | 'Analytics & Reporting'
  | 'Prior Authorization'
  | 'Claims'
  | 'Eligibility and Benefits'
  | 'Referrals'
  | 'PCLU'
  | 'Documents';

interface TinEntitlement {
  name: UserEntitlement;
  functions: string[];
}
// TODO: Needs to update tin fields
export interface TinData {
  number: string;
  contracted: boolean;
  sanctioned: boolean;
  name: string;
  hasComrcl: boolean;
  hasGovrnm: boolean;
  lob: string;
  entitlements: TinEntitlement[];
}

export const useGetUserProfileApi = () =>
  useQuery<GetUserProfileResponse>(
    {
      url: `/identity/v1/profile/user`,
    },
    { staleTime: Infinity },
  );

export const getTinsForDropdown = (
  tins?: TinData[],
  entitlement?: UserEntitlement, // todo: once api team refactors change to role
): Array<{ value: string; label: string }> =>
  tins
    ?.filter((tin) =>
      entitlement
        ? tin.entitlements.some((item) => item.name === entitlement)
        : true,
    )
    ?.map((tin) => ({
      value: `${tin.number}`,
      label: `${tin.number}${tin.name ? ' - ' : ''}${tin.name}`,
    }))
    .sort((a, b) => a.value.localeCompare(b.value)) || [];

import { useTranslation } from 'react-i18next';
import { useTrackPageAction } from '@cigna/shared/react/analytics-util';

// eslint-disable-next-line @nx/enforce-module-boundaries
import EvernorthLogo from '../../../../../../shared/angular/assets-ui/images/evn_logo_hs_r_rgb_white.svg';

import styles from './global-footer.module.scss';

export interface GlobalFooterProps {
  isAuthenticated: boolean;
}

export function GlobalFooter(props: GlobalFooterProps) {
  const { t } = useTranslation();
  const trackPageAction = useTrackPageAction();

  const handleLinkClick = (title: string) => {
    trackPageAction(
      {
        controlText: title,
        controlType: 'Click',
        controlName: 'footer-links',
      },
      {
        reqData: {
          requirementID: 'ac-Das002',
          userStory: 'ACNBPT4-285',
        },
      },
    );
  };

  const year = new Date().getFullYear();

  return (
    <footer className={styles.container}>
      <div className="enac-g-page-wrap">
        <div className={styles.footerInner}>
          <img
            src={EvernorthLogo}
            alt="Evernorth Health Services"
            width={'181'}
            height={'32'}
          />
          <hr />
          <div className={styles.legalLinks}>
            <a
              onClick={() => handleLinkClick(t('footer.legalLinks.terms'))}
              href="https://www.evernorth.com/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
              data-test-id="link-terms-of-use"
            >
              {t('footer.legalLinks.terms')}
            </a>

            <a
              onClick={() =>
                handleLinkClick(t('footer.legalLinks.additionalTerms'))
              }
              href="https://www.evernorth.com/site-specific-terms"
              target="_blank"
              rel="noopener noreferrer"
              data-test-id="link-site-specific-terms"
            >
              {t('footer.legalLinks.additionalTerms')}
            </a>

            <a
              onClick={() =>
                handleLinkClick(t('footer.legalLinks.privacyPolicy'))
              }
              href="https://www.evernorth.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              data-test-id="link-privacy-policy"
            >
              {t('footer.legalLinks.privacyPolicy')}
            </a>
          </div>
          {/* <hr /> */}
          <p className={styles.legalText}>{t('footer.copyright', { year })}</p>
        </div>
      </div>
    </footer>
  );
}

export default GlobalFooter;

/**
 * Analytics team wants English i18n values for analytics events regardless of the user's chosen language.
 *
 * This is handled by creating a list of all potentially tracked keys and mapping them to the respective English values.
 *
 * Simply pass the raw i18n strings to parseTranslationResourcesForAnalytics at app init.
 * The values are automatically read via getEnglishAnalyticsValue as it is baked into the analytics util's track action hook.
 */

import type { Resource, ResourceLanguage } from 'i18next';
import get from 'lodash/get';

const mappedAnalyticsToEnglish: Record<string, string> = {};
const mappedAnalyticsToEnglishWithInsertions: Array<{
  // current implementation currently matches/replaces by index. May need to be updated to match by key
  matcher: RegExp;
  toEnglish: (insertions: string[]) => string;
}> = [];

/**
 * Retrieves the English value for a given foreign value used in analytics events.
 *
 * @param foreignValue - The foreign value to be translated to English.
 * @returns The English value if found, otherwise returns the original foreign value.
 */
export const getEnglishAnalyticsValue = (foreignValue: string) => {
  const mappedValue = mappedAnalyticsToEnglish[foreignValue];
  if (mappedValue) {
    return mappedValue;
  }
  const mappedMatcher = mappedAnalyticsToEnglishWithInsertions.find(
    ({ matcher }) => matcher.test(foreignValue),
  );
  if (mappedMatcher) {
    const insertions = foreignValue.match(mappedMatcher.matcher) ?? [];
    insertions.shift();
    return mappedMatcher.toEnglish(insertions);
  }
  return foreignValue;
};

const saveToAnalyticsMap = (foreignValue: string, englishValue: string) => {
  if (foreignValue.includes('{{')) {
    mappedAnalyticsToEnglishWithInsertions.push({
      matcher: new RegExp(
        foreignValue
          .split(/{{|}}/)
          .map((a, i) => (i % 2 === 0 ? a : '(.*?)'))
          .join(''),
      ),
      toEnglish: (insertionValues: string[]) =>
        englishValue
          .split(/{{|}}/)
          .map((a, i) => (i % 2 === 0 ? a : insertionValues.shift() ?? a))
          .join(''),
    });
  } else {
    mappedAnalyticsToEnglish[foreignValue] = englishValue;
  }
};

const analyticsItems: Array<{
  keySuffix: string;
  handler: (foreignValue: string, englishValue: string) => void;
}> = [
  {
    keySuffix: 'Button',
    handler: (foreignValue, englishValue) => {
      saveToAnalyticsMap(foreignValue, englishValue);
    },
  },
  {
    keySuffix: 'Label',
    handler: (foreignValue, englishValue) => {
      saveToAnalyticsMap(foreignValue, englishValue);
    },
  },
  {
    keySuffix: 'MD',
    handler: (foreignValue, englishValue) => {
      setFromProps('ctaButton', englishValue, foreignValue);
      setFromProps('closeButton', englishValue, foreignValue);

      // links
      if (foreignValue.match(/\[(.*?)\]\s?\((.*?)\)/g)) {
        const foreignLinkLabels = foreignValue.match(/\[(.*?)\]/g);
        const englishLinkLabels = englishValue.match(/\[(.*?)\]/g);
        foreignLinkLabels?.forEach((foreignLabel, index) => {
          saveToAnalyticsMap(
            foreignLabel.replace(/\[|\]/g, ''),
            englishLinkLabels?.[index]?.replace(/\[|\]/g, '') ?? '',
          );
        });
      }
    },
  },
];

const setFromProps = (
  prop: string,
  englishValue: string,
  foreignValue: string,
) => {
  const parseProps = (input: string) => {
    let match: RegExpExecArray | null;
    const matches = [];

    const quoted = new RegExp(`${prop}="([^"]*)"`, 'g');
    while ((match = quoted.exec(input)) !== null) {
      if (match[1]) {
        matches.push(match[1]);
      }
    }

    const unquoted = new RegExp(`${prop}=(\\w*)\\W`, 'g');
    while ((match = unquoted.exec(input)) !== null) {
      if (match[1]) {
        matches.push(match[1]);
      }
    }

    return matches;
  };

  const englishMatches = parseProps(englishValue);
  const foreignMatches = parseProps(foreignValue);

  foreignMatches.forEach((foreignKey, index) => {
    saveToAnalyticsMap(
      flattenMarkdown(foreignKey),
      flattenMarkdown(englishMatches[index]),
    );
  });
};

const flattenMarkdown = (input: string) =>
  input
    .replace(/\{.*?\}/g, '') // remove attributes
    .replace(/\W?:+\w*\[(.*?)\]/g, ' $1 ') // extract bracketed inline content
    .replace(/\W?:.*/g, ' ') // remove directive blocks
    .replace(/\n#+/g, '\n') // un-format headings
    .replace(/\n-?\s/g, ' ') // un-format lists
    .replace(/\*/g, '') // un-format bold and italic
    .replace(/_/g, '') // un-format underscores
    .replace(/\n/g, ' ') // remove newlines
    .replace(/\s+/g, ' ') // remove double spaces
    .trim();

const mapObjectToPathsAndValues = (ipt: ResourceLanguage) => {
  const recurse = (
    input: object | number | string,
    flatKey: string[] = [],
  ): any => {
    if (input === 'undefined') {
      return;
    }
    if (typeof input === 'object' && !Array.isArray(input)) {
      return Object.entries(input).map(([key, item]) =>
        recurse(item, [...flatKey, key]),
      );
    } else if (typeof input === 'object' && Array.isArray(input)) {
      const lastFlatKey = flatKey[flatKey.length - 1];
      const flatKeyWithoutLast = flatKey.slice(0, -1);
      return input.map((item, index) =>
        recurse(item, [...flatKeyWithoutLast, `${lastFlatKey}[${index}]`]),
      );
    } else if (typeof input !== 'object') {
      return {
        value: input,
        flatKey: flatKey.join('.'),
        lastKey: flatKey[flatKey.length - 1].split('[')[0],
      };
    }
    return recurse(input, flatKey);
  };
  return [recurse(ipt)].flat().filter(Boolean) as Array<{
    value: number | string;
    flatKey: string;
    lastKey: string;
  }>;
};

export const parseTranslationResourcesForAnalytics = (
  resources: Resource,
  defaultEnglishKey: string,
) => {
  const englishTranslations = resources[defaultEnglishKey];

  Object.entries(resources).forEach(([lang, translation]) => {
    if (lang === defaultEnglishKey) {
      return;
    }

    const foreignPathsAndValues =
      mapObjectToPathsAndValues(translation).flat(9);
    return foreignPathsAndValues.forEach(
      ({ value: foreignValue, flatKey, lastKey }) => {
        analyticsItems.forEach(({ keySuffix, handler }) => {
          if (lastKey?.endsWith(keySuffix)) {
            handler(
              unNestTranslations(String(foreignValue), foreignPathsAndValues),
              String(get(englishTranslations, flatKey)),
            );
          }
        });
      },
    );
  });
};

const unNestTranslations = (
  value: string,
  pathsAndValues: Array<{
    value: number | string;
    flatKey: string;
    lastKey: string;
  }>,
) => {
  let v = value;
  const matches = v.match(/\$t\(.*?\)/g);
  if (!matches) {
    return v;
  }
  matches.forEach((match) => {
    const path = match.replace(/\$t\(|\)/g, '');
    const translation = pathsAndValues.find((item) =>
      item.flatKey.endsWith(path),
    );
    if (translation) {
      v = v.replace(match, String(translation.value));
    }
  });
  return v;
};

import { useTranslation } from 'react-i18next';
import { LeafCard, LeafHeading } from '@esi/leaf-web/lib/react';
import {
  LeafButtonRouter,
  PageWrapper,
} from '@cigna/enac-provider-web/shared/ui';

export function NotFound(props: { isAuthenticated?: boolean }) {
  const { t } = useTranslation();
  return (
    <PageWrapper pageTitle={t('notFound.heading')} variation="custom">
      <LeafHeading
        tagVariant="h1"
        type="title-xlarge"
        data-test-id="heading-main"
      >
        {t('notFound.heading')}
      </LeafHeading>
      <section className="enac-g-margin-top-64">
        <LeafCard>
          <p style={{ marginBottom: '2rem' }}>{t('notFound.body')}</p>
          {props.isAuthenticated ? (
            <LeafButtonRouter
              to="/dashboard"
              variant="primary"
              text={t('notFound.dashboardLink')}
            />
          ) : (
            <LeafButtonRouter
              to="/"
              variant="primary"
              text={t('notFound.loginLink')}
            />
          )}
        </LeafCard>
      </section>
    </PageWrapper>
  );
}

export default NotFound;

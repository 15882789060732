import { useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { useAuth0 } from '@auth0/auth0-react';
import { useEnvironment } from '@cigna/shared/react/environment-provider-util';
import { SessionTimeoutModal } from './session-timeout-modal';

const defaultIdleTimeoutMs = 1000 * 60 * 20; // 20 mins
const defaultPromptCountdownMs = 1000 * 60 * 5; // 5 mins

// to avoid refreshing token too often, we throttle to maximum of once per min
const throttleTimeMs = 1000 * 60 * 1; // 1 min

interface SessionTimeoutProps {
  idleTimeout?: number;
}

export function SessionTimeout({
  idleTimeout = defaultIdleTimeoutMs,
}: SessionTimeoutProps) {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [modalInstanceKey, setModalInstanceKey] = useState<string | false>(
    false,
  );
  const { environment } = useEnvironment();

  const handleCloseModal = () => {
    setModalInstanceKey(false);
    extendSession();
  };

  const handleOpenModal = () => {
    setModalInstanceKey(`countdown-${new Date()}`);
  };

  const extendSession = () => {
    getAccessTokenSilently({ cacheMode: 'off' });
    reset();
  };

  const forceLogout = () => {
    logout({
      logoutParams: {
        returnTo: environment.auth0.redirect_uri,
      },
    });
  };

  const checkActivity = () => {
    const elapsedTimeMs = getTotalElapsedTime();

    // if its been less than 1m we skip the first token refresh
    if (elapsedTimeMs > 60000 && !isPrompted()) {
      extendSession();
    }
  };

  const { isPrompted, reset, getTotalElapsedTime, getRemainingTime } =
    useIdleTimer({
      throttle: throttleTimeMs,
      onPrompt: handleOpenModal,
      onIdle: forceLogout,
      timeout: idleTimeout,
      promptBeforeIdle: defaultPromptCountdownMs,
      onAction: () => {
        checkActivity();
      },
      timers: workerTimers,
    });

  const timeRemainingMs = getRemainingTime();

  return (
    <SessionTimeoutModal
      handleCloseModal={handleCloseModal}
      modalInstanceKey={modalInstanceKey}
      idleTimeout={idleTimeout}
      timeRemainingMs={timeRemainingMs}
    />
  );
}

import type { EnacEnvironmentConfig } from '@cigna/enac-provider-web/shared/util';

export const environment: EnacEnvironmentConfig = {
  rootApiUrl: 'https://p-provider-care.digitaledge.evernorth.com',
  auth0: {
    audience: 'https://p-provider-care.digitaledge.evernorth.com',
    clientId: 'WChvzf72q0gcNl5SxsuZi7A2ZoFQgez9',
    connection: 'UniversalLogin-ENAC',
    domain: 'p.universal.login.evernorth.com',
    scope: 'openid profile email',
    redirect_uri: 'https://providercare.evernorth.com',
    impersonationConnection: 'workforce',
    impersonationReturnTo: '/dashboard',
  },
  launchDarkly: {
    environment: 'prod',
    clientSideID: '657747ab49fce10fa3640d5b',
  },
  contentful: {
    space: 'dy4ji17036nn',
    env: 'master',
    accessToken: 'xbkx9SXqhwXeyokgOfxGprSnJCuPQiF6oNdPybt0eDM',
  },
  routeBaseName: '',
  brandName: '',
  production: true,
  debug: false,
  google: {
    placesApiKey: 'AIzaSyDLODMlt7W3OfgcY-OtHdRwCL5zCf-Ik6A',
  },
  chcpPortalUrl: 'https://cignaforhcp.cigna.com',
};

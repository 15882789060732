import axios, { type AxiosResponse } from 'axios';
import merge from 'deepmerge';
import type { ResourceLanguage, ResourceKey, Resource } from 'i18next';
import type {
  HttpBackendOptions,
  RequestCallback,
  RequestResponse,
} from 'i18next-http-backend';

const MANIFEST_MAX_AGE = 60;
const CDN_CONTENT_MAX_AGE = 60 * 60 * 24 * 7;
let CACHED_MANIFEST_DATA: null | AxiosResponse = null;

export interface GetPathFromManifest {
  languages: string[];
  namespaces: string[];
  baseUrl?: string;
  instance: string;
}

export function i18nParseLoadPayload(translations?: Resource) {
  return (languages: string[], namespaces: string[]) => {
    if (translations?.[languages[0]]?.[namespaces[0]]) {
      return translations[languages[0]][namespaces[0]];
    }
    return {};
  };
}

const normalizeLanguageKeyForManifest = (languageKey: string) =>
  `${languageKey.toLowerCase().replace('-us', '')}-us`;

export async function getPathFromManifest({
  languages,
  namespaces,
  baseUrl,
  instance,
}: GetPathFromManifest): Promise<string | null> {
  const host = baseUrl ?? 'https://evernorth.test.host.com';
  const manifestUrl = new URL(`/content/${instance}/manifest.json`, host);
  try {
    if (baseUrl && !baseUrl.includes('test.host.com')) {
      const response = await getManifestData(manifestUrl);
      const manifest = response?.data;
      const [lng] = languages;
      const [ns] = namespaces;
      const dictionaries =
        manifest?.public?.[normalizeLanguageKeyForManifest(lng)];
      if (dictionaries?.[ns]) {
        const url = new URL(dictionaries[ns], baseUrl).href;
        // append the language and namespace filters to the url for usage in i18nRequest()
        return `${url}#${lng}:${ns}`;
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug('Unable to get path from manifest');
  }

  return null;
}

export async function getManifestData(manifestUrl: URL) {
  if (manifestUrl.href.includes('manifest.json') && !CACHED_MANIFEST_DATA) {
    const client = axios.create({
      baseURL: manifestUrl.origin,
      headers: {
        'Cache-Control': `max-age=${MANIFEST_MAX_AGE}`,
      },
    });
    const response = await client.get(
      `${manifestUrl.pathname}?t=${Date.now()}`,
    );
    CACHED_MANIFEST_DATA = response;
  }
  return CACHED_MANIFEST_DATA;
}

export async function fetchI18n(
  url: string,
  translations: ResourceKey,
  clientId?: string,
) {
  const i18nUrl = new URL(url);
  const client = axios.create({
    baseURL: i18nUrl.origin,
    headers: {
      'Cache-Control': `max-age=${CDN_CONTENT_MAX_AGE}`,
    },
  });

  try {
    const response = await client.get(i18nUrl.pathname);
    const { status, data } = response;

    if (status === 200) {
      const combinedCdnTranslations = i18nMergeClientTranslations(
        data,
        clientId,
      );

      const content = merge<ResourceKey>(translations, combinedCdnTranslations);

      return { status, data: content };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug(
      'Error fetching i18n data, using default translations',
      error,
    );
  }

  return { status: 200, data: translations };
}

export function i18nRequest(languageTranslations: Resource) {
  return async (
    options: HttpBackendOptions,
    url: string,
    payload: ResourceLanguage,
    callback: RequestCallback,
  ) => {
    try {
      // lang and namespace filters added to url in getPathFromManifest()
      const [lng, ns] = url.split('#')[1].split(':');
      const translations = languageTranslations[lng][ns];
      const { status, data } = await fetchI18n(url, translations);
      callback(null, { status, data });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug('Error making i18n call');
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      callback(e, {} as RequestResponse);
    }
  };
}

export const defaultNamespaceResolver = (ns: string) => ns.includes('feature');

/**
 * Provides the url to load the resource data from. The `baseUrl` variable is referenced in the application and reassigned here to prevent repeated calls.
 */
export function i18nLoadPath(
  manifestInstance: string,
  baseUrl: string,
  resolver = defaultNamespaceResolver,
) {
  return async (languages: string[], namespaces: string[]) => {
    const [ns] = namespaces;
    const instance = manifestInstance;

    return resolver(ns)
      ? getPathFromManifest({
          baseUrl,
          languages,
          namespaces,
          instance,
        })
      : null;
  };
}

export const i18nMergeClientTranslations = (
  translations: ResourceLanguage & {
    default?: Record<string, ResourceKey>;
    clients?: Record<string, ResourceKey>;
  },
  clientId?: string,
): ResourceLanguage => {
  const defaultTranslations = translations.default || translations;
  const clientTranslations = translations.clients
    ? clientId && translations.clients[clientId]
    : {};

  if (!clientTranslations) {
    return defaultTranslations as ResourceLanguage;
  }

  return merge(
    defaultTranslations as ResourceLanguage,
    clientTranslations as ResourceLanguage,
  );
};

export * from 'react-i18next';

import { useState, useEffect } from 'react';

import {
  type AssetFields,
  createClient,
  type EntriesQueries,
  type Entry,
  type EntrySkeletonType,
  type LocaleOption,
} from 'contentful';
import { useEnvironment } from '@cigna/shared/react/environment-provider-util';

export type ContentfulErrorCode =
  | 'BadRequest'
  | 'InvalidQuery'
  | 'AccessTokenInvalid'
  | 'AccessDenied'
  | 'NotFound'
  | 'VersionMismatch'
  | 'ValidationFailed'
  | 'UnknownField'
  | 'InvalidEntry'
  | 'RateLimitExceeded'
  | 'ServerError'
  | 'Hibernated';

export interface ContentfulError {
  sys: {
    type: 'Error';
    id: ContentfulErrorCode;
  };
  message: string;
}
const useContentfulClient = () => {
  const { environment } = useEnvironment();

  return createClient({
    accessToken: environment.contentful?.accessToken || '',
    space: environment.contentful?.space || '',
    environment: environment.contentful?.env || '',
  });
};

const useContentfulEntries = <T extends EntrySkeletonType>(
  query?: EntriesQueries<EntrySkeletonType, 'WITHOUT_UNRESOLVABLE_LINKS'>,
) => {
  const client = useContentfulClient();

  const [data, setData] = useState<
    Array<Entry<T, 'WITHOUT_UNRESOLVABLE_LINKS'>>
  >([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<ContentfulError | undefined>(undefined);

  useEffect(() => {
    client.withoutUnresolvableLinks
      .getEntries<T>(query)
      .then((entries) => {
        setData(entries.items);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);
  return { data, isLoading, error };
};

const useContentfulAsset = (
  assetId: string,
  query?: LocaleOption | undefined,
) => {
  const client = useContentfulClient();

  const [data, setData] = useState<AssetFields | undefined>(undefined);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<ContentfulError | undefined>(undefined);

  useEffect(() => {
    client
      .getAsset(assetId, query)
      .then((entries) => {
        setData(entries.fields);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);
  return { data, isLoading, error };
};

export { useContentfulEntries, useContentfulAsset };

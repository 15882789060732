import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import caretRightIcon from '@esi/leaf-icons/lib/icons/System/Regular/Caret Right.svg?raw';
import externalLinkIcon from '@esi/leaf-icons/lib/icons/System/Regular/External Link.svg?raw';

import LeafIcon from '@esi/leaf-icons/lib/react/LeafIcon';

import { LeafBadge, LeafLoadingSpinner } from '@esi/leaf-web/lib/react';
import type { LeafBadge as LeafBadgeType } from '@esi/leaf-web/lib/wc/components/badge/badge';
import {
  type WebActionData,
  useTrackPageAction,
  type EventConfigData,
} from '@cigna/shared/react/analytics-util';
import styles from './list-card.module.scss';
export interface ListCardItem {
  headline?: string;
  description?: string;
  shouldDisableLink?: boolean;
  external?: boolean;
  link?: string;
  iconSvg?: string;
  last?: boolean; // todo: ideally find way to avoid needing this
  loading?: boolean;
  badge?: {
    text: string;
    status: LeafBadgeType['status'];
    variant?: LeafBadgeType['variant'];
  };
  testId?: string;
  analytics?: {
    actionData: WebActionData;
    eventConfig?: EventConfigData;
  };
}

interface CardLinkProps {
  external: boolean;
  shouldDisableLink?: boolean;
  link?: string;
  track?: () => void;
}

function CardLink(props: PropsWithChildren<CardLinkProps>) {
  if (props.external && props.link && !props.shouldDisableLink) {
    return (
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        className="enac-g-router-link"
        onClick={() => (props.track ? props.track() : null)}
      >
        {props.children}
      </a>
    );
  }
  if (props.link && !props.shouldDisableLink) {
    return (
      <Link
        to={props.link}
        className="enac-g-router-link"
        onClick={() => (props.track ? props.track() : null)}
      >
        {props.children as any}
      </Link>
    );
  }
  return <div>{props.children}</div>;
}

export function ListCard(props: PropsWithChildren<ListCardItem>) {
  const trackPageAction = useTrackPageAction();

  return (
    <CardLink
      link={props.link}
      external={props.external || false}
      shouldDisableLink={props.shouldDisableLink}
      track={() =>
        props.analytics
          ? trackPageAction(
              props.analytics.actionData,
              props.analytics.eventConfig,
            )
          : null
      }
    >
      <div
        className={`${styles.cardListItem} ${
          props.last === true ? styles.cardListItemLast : ''
        }`}
        data-test-id={props.testId}
      >
        {(props.iconSvg || props.loading) && (
          <div className={styles.iconLeftWrapper}>
            {props.loading && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '32px',
                  width: '32px',
                }}
                data-test-id="loading-spinner"
              >
                <LeafLoadingSpinner size="small" />
              </div>
            )}
            {!props.loading && props.iconSvg && (
              <LeafIcon
                className={styles.iconLeft}
                height={32}
                width={32}
                svg={props.iconSvg}
                aria-hidden
              />
            )}
          </div>
        )}
        <div className={styles.textWrapper}>
          <div className={styles.headline} data-test-id="list-card-headline">
            {props.loading ? <span>Loading</span> : props.headline}
          </div>
          {props.description && (
            <div className={styles.description}>{props.description}</div>
          )}
          {props.children && (
            <div className={styles.childContent}>{props.children}</div>
          )}
        </div>
        <div className={styles.iconRightWrapper}>
          {props.badge && (
            <LeafBadge
              data-test-id="list-card-badge"
              text={props.badge.text}
              status={props.badge.status}
              variant={props.badge.variant}
            />
          )}
          {props.link && (
            <LeafIcon
              svg={props.external ? externalLinkIcon : caretRightIcon}
              className={styles.iconRight}
              key={`icon-${props.external}`}
              aria-hidden
            />
          )}
        </div>
      </div>
    </CardLink>
  );
}

export default ListCard;

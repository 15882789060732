import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  LeafButton,
  LeafCheckboxField,
  LeafCheckboxFieldItem,
} from '@esi/leaf-web/lib/react';

import type { RequirementInfo } from '@cigna/shared/analytics/core';
import { useTrackPageAction } from '@cigna/shared/react/analytics-util';
import FilterToggle from '../filter-toggle/filter-toggle';
import styles from './filter-panel-checkboxes.module.scss';

export interface FilterOption {
  label: string;
  data: string;
}

export interface FilterOptionCheckbox extends FilterOption {
  value: boolean;
}

export interface FilterPanelCheckboxesProps {
  btnLabel: string;
  options: FilterOption[];
  choices: string[];
  onApply: (values: string[]) => void;
  onClear?: () => void;
  testId: string;
  analytics?: FilterAnalytics;
  disabled?: boolean;
}

interface FilterAnalytics {
  controlName: string;
  attributeKey: string;
  reqData: RequirementInfo;
}

interface FormValues {
  filters: FilterOptionCheckbox[];
}

export function FilterPanelCheckboxes(props: FilterPanelCheckboxesProps) {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterChoices, setFilterChoices] = useState<string[]>([]);

  const { register, control, getValues, setValue } = useForm<FormValues>({
    defaultValues: {
      filters: props.options,
    },
  });

  const { fields } = useFieldArray({
    name: 'filters',
    control,
  });

  const trackPageAction = useTrackPageAction();

  const handleApply = () => {
    const selectedChoices = getValues().filters.filter((v) => v.value);

    props.onApply(selectedChoices.map((d) => d.data));
    setShowFilter(false);

    if (props.analytics) {
      trackPageAction(
        {
          controlText: 'Apply',
          controlType: 'Click',
          controlName: props.analytics.controlName,
          attributes: selectedChoices.map((choice) => ({
            key: props.analytics?.attributeKey || '',
            value: choice.label,
          })),
        },
        {
          reqData: props.analytics.reqData,
        },
      );
    }
  };

  const handleClear = () => {
    setFilterChoices([]);
    setValue(
      'filters',
      props.options.map((opt) => ({ ...opt, value: false })),
    );
  };

  const handleOpen = () => {
    setValue(
      'filters',
      props.options.map((opt) => ({
        ...opt,
        value: props.choices.includes(opt.data) ? true : false,
      })),
    );
    setFilterChoices(props.choices);
    setShowFilter(true);
  };

  const handleClose = () => {
    setShowFilter(false);
  };

  return (
    <FilterToggle
      label={`${props.btnLabel}${
        props.choices.length ? ` (${props.choices.length})` : ''
      }`}
      isOpen={showFilter}
      onOpen={handleOpen}
      onClosed={handleClose}
      testId={props.testId}
      disabled={props.disabled}
    >
      <div className={styles.container}>
        <div className={styles.panelBody}>
          <LeafCheckboxField fieldNote="" label="">
            {fields.map((field, index) => (
              <LeafCheckboxFieldItem
                {...register(`filters.${index}.value`)}
                key={`${props.testId}-${index}`}
                label={field.label}
                checked={filterChoices.includes(field.data)}
              />
            ))}
          </LeafCheckboxField>
        </div>
        <div className={styles.panelFooter}>
          <LeafButton
            data-test-id={`${props.testId}-apply-btn`}
            onClick={handleApply}
            text="Apply"
            variant="primary"
            size="sm"
            fullWidth
          />
          <LeafButton
            data-test-id={`${props.testId}-clear-btn`}
            onClick={handleClear}
            text="Clear"
            variant="tertiary"
            size="sm"
            fullWidth
          />
        </div>
      </div>
    </FilterToggle>
  );
}

export default FilterPanelCheckboxes;

import type {
  ClaimAttachmentApiEndpoint,
  ClaimAttachmentType,
  FileMetaData,
  StandardApiResponse,
} from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export interface ClaimAttachmentsMetadataRequestBody {
  serviceReferenceNumber: string;
  attachmentType: ClaimAttachmentType;
  files: FileMetaData[];
  claimId?: string;
}

export const usePostClaimAttachmentsMetadataApi = (
  apiEndpoint: ClaimAttachmentApiEndpoint,
) =>
  useMutation<StandardApiResponse, ClaimAttachmentsMetadataRequestBody>({
    method: 'POST',
    url: `/claims/attachments/v1/${apiEndpoint}/upload`,
  });

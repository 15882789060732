export enum ClaimStatus {
  InProgress = 'IN PROGRESS',
  Processed = 'PROCESSED',
  Pended = 'PENDED',
  Denied = 'DENIED',
  Paid = 'PAID',
}

export enum AttachmentStatus {
  Unmatched = 'UMTCH',
  Matched = 'MTCH',
  Rejected = 'REJECTED',
}

import { type MouseEventHandler, forwardRef } from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import { LeafButton } from '@esi/leaf-web/lib/react';

type Ref = typeof LeafButton;

export const LeafButtonRouter = forwardRef<Ref, any>(
  ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
    const href = useHref(to);
    const handleClick: MouseEventHandler = useLinkClickHandler(to, {
      replace,
      state,
      target,
    });

    return (
      <LeafButton
        {...rest}
        href={href}
        onClick={(event) => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event);
          }
        }}
        ref={ref}
      />
    );
  },
);

export default LeafButtonRouter;

// NOTE: Only use this as a last resort for icons that do not exist in one of our main icon packages

// check these first!

// @esi/leaf-icons/lib/icons/
// @phosphor-icons/core/assets

// if you don't see the icon there check back with designer to see if we can use one of the existing icons from leaf or phosphor instead

export const customPlusIcon = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.287 0V12.2869H0V19.7131H12.287V32H19.7131V19.7131H32.0001V12.2869H19.7131V0H12.287Z" fill="currentColor"/>
</svg>`;

export const customCheckIcon = `<svg width="54" height="40" viewBox="0 0 54 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.0898 26.4262L7.49246 13.8201L0.701172 20.6114L20.0898 40L53.2985 6.79129L46.516 0L20.0898 26.4262Z" fill="currentColor"/>
</svg>
`;

import { LeafAlert } from '@esi/leaf-web/lib/react';
import styles from './toast-alert.module.scss';

export interface ToastMessage {
  status: 'success' | 'error';
  message: string;
  showMessage: boolean;
}

export interface ToastAlertProps extends ToastMessage {
  hideCloseIcon?: boolean;
  onClose?: () => void;
}

export function ToastAlert(props: ToastAlertProps) {
  const handleOnClose = () => {
    props.onClose && props.onClose();
  };
  return (
    props.showMessage && (
      <LeafAlert
        className={styles.message}
        notDismissible={props.hideCloseIcon === true}
        onDismiss={handleOnClose}
        status={props.status}
      >
        <div className={styles.messageText}>{props.message}</div>
      </LeafAlert>
    )
  );
}

export default ToastAlert;

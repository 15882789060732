/* eslint-disable @nx/enforce-module-boundaries */
import React, { memo, useEffect, useState } from 'react';
import closeIcon from '@esi/leaf-icons/lib/icons/System/Regular/Close.svg?raw';
import {
  LeafFieldNote,
  LeafLoadingSpinner,
  LeafButton,
} from '@esi/leaf-web/lib/react';
import {
  useDeleteClaimAttachmentsMetadataApi,
  usePostClaimAttachmentsSignedUrlApi,
} from '@cigna/enac-provider-web/shared/data-access';
import {
  type ClaimAttachmentApiEndpoint,
  type ClaimAttachmentType,
  type ClaimSignedUrlEndpoint,
  getFileSize,
} from '@cigna/enac-provider-web/shared/util';

import styles from './file-upload-item.module.scss';

export interface FileUploadItemProps {
  file: File;
  isValid: boolean;
  attachmentType: ClaimAttachmentType;
  metadataApiEndpoint: ClaimAttachmentApiEndpoint;
  signedUrlApiEndpoint: ClaimSignedUrlEndpoint;
  serviceReferenceNumber: string;
  onRemoveFile?: (file: File) => void;
}

enum FileUploadStatus {
  InProgress,
  Success,
  Error,
}

const FileUploadItem = memo((props: FileUploadItemProps) => {
  const [uploadStatus, setUploadStatus] = useState<
    FileUploadStatus | undefined
  >(undefined);

  const claimAttachmentsSignedUrlApi = usePostClaimAttachmentsSignedUrlApi(
    props.signedUrlApiEndpoint,
  );

  const removeClaimAttachmentsMetadataApi =
    useDeleteClaimAttachmentsMetadataApi(
      props.metadataApiEndpoint,
      props.serviceReferenceNumber,
      props.file.name,
      props.attachmentType,
    );

  const handleRemoveFile = React.useCallback(
    () => {
      removeFileMetaData();
      props.onRemoveFile && props.onRemoveFile(props.file);
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [props],
  );

  const removeFileMetaData = () => {
    removeClaimAttachmentsMetadataApi.mutate(undefined, {
      onError: () => {
        // TODO: error state handling pending
      },
    });
  };

  // Step2: Getting S3 Signed Url
  const attachmentsUpload = () => {
    setUploadStatus(FileUploadStatus.InProgress);
    claimAttachmentsSignedUrlApi.mutate(
      { name: `${props.serviceReferenceNumber}/${props.file.name}` },
      {
        onSuccess: (res) => {
          putFileToS3(res.result.presignedURL);
        },
        onError: () => {
          // TODO: error state handling pending
          setUploadStatus(FileUploadStatus.Error);
          removeFileMetaData();
        },
      },
    );
  };

  // Step3: PUT file in S3 bucket with signed URL
  // TODO: Needs to move fetch call in seperate file
  const putFileToS3 = async (url: string) => {
    // TODO: needs to confirm headers
    const headers = {
      Accept: props.file.type,
      'Content-Type': props.file.type,
      'Content-Length': props.file.size.toString(),
    };
    await fetch(url, {
      method: 'PUT',
      body: props.file,
      headers,
    })
      .then(() => setUploadStatus(FileUploadStatus.Success))
      .catch(() => {
        setUploadStatus(FileUploadStatus.Error);
        removeFileMetaData();
      });
  };

  useEffect(
    () => {
      if (props.isValid && uploadStatus === undefined) {
        attachmentsUpload();
      }
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className={styles.fileItem} data-test-id="uploaded-files">
      <div
        className={`${styles.fileInfo} ${props.isValid ? '' : styles.error}`}
      >
        <LeafButton
          text="Remove file"
          variant="tertiary"
          hideText={true}
          svg={closeIcon}
          iconPosition="before"
          onClick={handleRemoveFile}
          className={styles.deleteIcon}
          data-test-id={`file-remove-btn`}
          size="sm"
        />
        <div>
          {props.file.name} - {getFileSize(props.file.size)}
        </div>
        <div className={styles.uploadStatusContainer}>
          {uploadStatus === FileUploadStatus.InProgress && (
            <LeafLoadingSpinner
              size="small"
              label="uploading..."
              data-test-id="loading-spinner"
            />
          )}
          {uploadStatus === FileUploadStatus.Success && (
            <LeafFieldNote
              isSuccess
              className={styles.success}
              data-test-id="file-upload-success-note"
            >
              Uploaded
            </LeafFieldNote>
          )}
          {uploadStatus === FileUploadStatus.Error && (
            <LeafFieldNote
              isError
              aria-live="polite"
              data-test-id="file-upload-error-note"
              className={styles.error}
            >
              Error
            </LeafFieldNote>
          )}
        </div>
      </div>
    </div>
  );
});

export default FileUploadItem;

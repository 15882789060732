import type {
  ClaimAttachmentType,
  StandardApiResponse,
  ClaimAttachmentApiEndpoint,
} from '@cigna/enac-provider-web/shared/util';
import { useMutation } from '@cigna/shared/react/react-query-web-data-access';

export const useDeleteClaimAttachmentsMetadataApi = (
  apiEndpoint: ClaimAttachmentApiEndpoint,
  serviceReferenceNumber: string,
  fileName: string,
  attachmentType: ClaimAttachmentType,
) =>
  useMutation<StandardApiResponse>({
    method: 'DELETE',
    url: `/claims/attachments/v1/${apiEndpoint}/delete`,
    params: {
      serviceReferenceNumber,
      fileName,
      attachmentType,
    },
  });

import {
  type ReactNode,
  createContext,
  useContext,
  type FC,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import merge from 'lodash/merge';
import type { PageDataMap, UserInfo } from '@cigna/shared/analytics/core';
import {
  useEnvironment,
  type EnvironmentConfig,
} from '@cigna/shared/react/environment-provider-util';
import { useTrackPageView } from '../hooks';
import { useTrackHasLoggedIn } from '../hooks/useTrackHasLoggedIn';
import type { WebAnalyticsService } from '../interfaces';
import type { WebAnalyticsConfiguration } from '../models';
import ReactAnalyticsService from '../services/react-analytics-service';
import { mapPageInfoConfigToPageInfo } from '../utils/data-mapping.util';
import { getCurrentPath } from '../utils/location.util';

const AnalyticsContext = createContext<WebAnalyticsService | null>(null);

const AutoTrackingPageInfoContext = createContext<
  PageDataMap | null | undefined
>(null);

const useAnalytics = () => useContext(AnalyticsContext);

interface AgentInfo {
  impersonatedAgentID?: string;
  impersonatedRole?: string;
}

const auth0Default: Partial<EnvironmentConfig['auth0']> = {};

const useUserInfo = () => {
  const {
    environment: { auth0 = auth0Default },
  } = useEnvironment();
  const {
    user: {
      email,
      'ent.personResourceId': personResourceID,
      'ul.sub': ulSub,
    } = {},
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const [{ impersonatedAgentID, impersonatedRole }, setAgentInfo] =
    useState<AgentInfo>({});

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        cacheMode: 'cache-only',
        authorizationParams: {
          audience: auth0.audience,
          scope: auth0.scope,
        },
      })
        .then((token) => {
          const decodedToken: { [key: string]: unknown } = jwt_decode(token);
          const newImpersonatedAgentID = decodedToken['agent.operatorId'];
          const newImpersonatedRole = decodedToken['agent.operatorRole'];

          setAgentInfo({
            impersonatedAgentID:
              typeof newImpersonatedAgentID === 'string'
                ? newImpersonatedAgentID
                : undefined,
            impersonatedRole:
              typeof newImpersonatedRole === 'string'
                ? newImpersonatedRole
                : undefined,
          });
        })
        .catch((e) => {
          console.error('Failed to get access token:', e);
        });
    }
  }, [auth0.audience, auth0.scope, getAccessTokenSilently, isAuthenticated]);

  return useMemo(() => {
    const userInfo: UserInfo = {
      id: personResourceID,
      ssoID: ulSub && impersonatedAgentID ? ulSub : email || personResourceID,
      impersonatedRole,
      impersonatedAgentID,
      loggedInStatus: 'LoggedIn',
      personResourceID,
    };
    return { userInfo, isAuthenticated, isLoading };
  }, [
    personResourceID,
    ulSub,
    impersonatedAgentID,
    email,
    impersonatedRole,
    isAuthenticated,
    isLoading,
  ]);
};

const useCurrentPageInfo = () => useContext(AutoTrackingPageInfoContext);

const AutoTrackPageViewEvents: FC = () => {
  const location = useLocation();
  const trackPageView = useTrackPageView();
  const analyticsService = useAnalytics();
  const currentPageInfo = useCurrentPageInfo();
  const config = analyticsService?.getConfig();
  useTrackHasLoggedIn();

  useEffect(() => {
    const currentPath = getCurrentPath();
    if (config && currentPageInfo?.[currentPath]) {
      trackPageView();
    }
  }, [location.pathname, currentPageInfo, config, trackPageView]);

  return null;
};

const getPageInfo = (
  config: WebAnalyticsConfiguration,
  pageDataMap: PageDataMap | null = null,
) => {
  const currentPath = getCurrentPath();
  const currentMap = pageDataMap
    ? pageDataMap[currentPath]
    : config.mappings?.pageDataMap?.[currentPath];

  return mapPageInfoConfigToPageInfo(
    currentMap,
    config.name,
    window.location.href,
  );
};

interface AnalyticsProviderProps {
  config: WebAnalyticsConfiguration;
  children: ReactNode;
}

const AnalyticsContextProvider: FC<AnalyticsProviderProps> = ({
  config,
  children,
}) => {
  // first check if parentAnalyticsProvider exists.
  const parentAnalytics = useAnalytics();

  // if parent analytics exists, get the config from the parent and merge with incoming config for current provider
  const analyticsServiceProvider = useMemo(() => {
    const mergedConfig = parentAnalytics
      ? merge({}, parentAnalytics.getConfig(), config)
      : config;
    return new ReactAnalyticsService(mergedConfig);
  }, [config, parentAnalytics]);

  return (
    <AnalyticsContext.Provider value={analyticsServiceProvider}>
      {!config.autoPageEventTrackingDisabled ? (
        <AutoTrackingPageInfoContext.Provider
          value={config.mappings?.pageDataMap}
        >
          <AutoTrackPageViewEvents />
        </AutoTrackingPageInfoContext.Provider>
      ) : null}
      {children}
    </AnalyticsContext.Provider>
  );
};

interface AnalyticsProviderWrapperProps {
  config?: WebAnalyticsConfiguration;
  children: ReactNode;
}

const AnalyticsProvider: FC<AnalyticsProviderWrapperProps> = ({
  config,
  children,
}) =>
  config ? (
    <AnalyticsContextProvider config={config}>
      {children}
    </AnalyticsContextProvider>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );

export {
  AnalyticsProvider,
  useAnalytics,
  useUserInfo,
  useCurrentPageInfo,
  getPageInfo,
};
export default AnalyticsProvider;

import accountIcon from '@esi/leaf-icons/lib/icons/System/Regular/Account.svg?raw';
import { useGetUserProfileApi } from '@cigna/enac-provider-web/shared/data-access';
import { LeafButtonRouter } from '@cigna/enac-provider-web/shared/ui';
import { useTrackPageAction } from '@cigna/shared/react/analytics-util';

export function UtilNavigation() {
  const { data } = useGetUserProfileApi();
  const trackPageAction = useTrackPageAction();

  if (data?.identity) {
    return (
      <LeafButtonRouter
        to={'/account'}
        onClick={() => {
          trackPageAction(
            {
              controlText: `${data.identity.firstName} ${data.identity.lastName}`,
              controlType: 'Click',
              controlName: 'top-profile-icon',
              controlRegion: 'header',
            },
            {
              reqData: {
                requirementID: 'ac-Das003',
                userStory: 'ACNBPT4-285',
              },
            },
          );
        }}
        data-test-id="btn-account-link"
        text={`${data.identity.firstName} ${data.identity.lastName}`}
        iconPosition="before"
        svg={accountIcon}
        variant="tertiary"
        size="md"
      />
    );
  }
}

export default UtilNavigation;

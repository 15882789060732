import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { useEnvironment } from '@cigna/shared/react/environment-provider-util';

export function useIsImpersonator() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { environment } = useEnvironment();
  const [isImpersonating, setIsImpersonating] = useState(false);

  useEffect(() => {
    async function getIsImpersonating() {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently({
            cacheMode: 'cache-only',
            authorizationParams: {
              audience: environment.auth0.audience,
              scope: environment.auth0.scope,
            },
          });
          const decodedToken: { [key: string]: unknown } = jwt_decode(token);
          if (Boolean(decodedToken['agent.operatorId'])) {
            setIsImpersonating(true);
          } else {
            setIsImpersonating(false);
          }
        } catch (error) {
          console.error('Failed to get access token:', error);
        }
      }
    }
    getIsImpersonating();
  }, [isAuthenticated, getAccessTokenSilently, environment]);

  return isImpersonating;
}
